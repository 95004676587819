import React, { useState } from 'react';
import './mdeform.css';
import FormTextInput from '../FormComponents/FormTextInput/FormTextInput';
import { Validate } from '../FormValidation/FormValidation';
import ErrorIcon from '../../assets/images/errorIcon.png';
import FormButton from '../FormComponents/FormButton/FormButton';
import { BASE_URL } from '../../utils/api';

export default function MDEForm(props) {
  const [user, setUser] = useState({
    name: '',
    mobileNo: '',
    pincode: '',
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submit', user);

    const isvaliddata = Validate(user);
    if (Object.keys(isvaliddata).length === 0) {
      setUser({
        name: '',
        mobileNo: '',
        pincode: '',
      });

      setErrors('');

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`${BASE_URL}contact/mde`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result?.status === 'OK') {
            props?.setSuccessPopUp(true);
          } else {
            props?.setSuccessPopUp(true);
            props.setErrorMessage(true);
          }
        })
        .catch((err) => {
          console.error(err);
          props?.setSuccessPopUp(true);
          props.setErrorMessage(true);
        });
    } else {
      setErrors(isvaliddata);
    }
  };

  return (
    <div className="_MDEForm_plot">
      <div className="_MDEform_header">Contact Us</div>
      <form className="_mdeform">
        <FormTextInput
          onChange={handleChange}
          value={user.name}
          name={'name'}
          placeholder="Enter Your Name *"
          type="text"
          fClass={'fClass'}
          error={
            errors.name ? (
              <div className="errorTexts">
                <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                {errors?.name}
              </div>
            ) : (
              ''
            )
          }
        />
        <FormTextInput
          onChange={handleChange}
          value={user.mobileNo}
          name={'mobileNo'}
          placeholder="Enter Your Mobile Number *"
          type="number"
          fClass={'fClass'}
          error={
            errors.mobileNo ? (
              <div className="errorTexts">
                <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                {errors?.mobileNo}
              </div>
            ) : (
              ''
            )
          }
        />
        <FormTextInput
          onChange={handleChange}
          value={user?.pincode}
          name={'pincode'}
          placeholder="Enter Pincode *"
          type="text"
          fClass={'fClass'}
          error={
            errors.pincode ? (
              <div className="errorTexts">
                <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                {errors?.pincode}
              </div>
            ) : (
              ''
            )
          }
        />
        <FormButton title="Submit" handleSubmit={handleSubmit} />
      </form>
    </div>
  );
}
