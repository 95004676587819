import React, { useState } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import './mde.css';
import MDE_LOGO from '../../../assets/images/mde_logo.png';
import MdeSolutions from '../../../components/MdeSolutions';
import MDEForm from '../../../components/MDEForm';
import MDESuccessPopUp from '../../../components/MDESuccessPopUp';
import MDEOfferings from '../../../components/MDEOfferings';

const MDE = () => {
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  return (
    <div className="main-div desktop-view">
      {/* <Header />
      <BreadCrumb /> */}
      <div className="_mde_container">
        <div className="_mde_header">
          <div className="_mde_logo">
            <img src={MDE_LOGO} alt="mde-logo" />
          </div>
          <div className="_mde_title">Micro Dairy Enterprise</div>
        </div>
        <div className="_mde_solutions_settings">
          <MdeSolutions />
          <MDEForm setSuccessPopUp={setSuccessPopUp} setErrorMessage={setErrorMessage} />
        </div>
        <MDEOfferings />
      </div>
      {successPopUp && <MDESuccessPopUp setSuccessPopUp={setSuccessPopUp} errorMessage={errorMessage} />}
      {/* <Footer /> */}
    </div>
  );
};

export default MDE;
