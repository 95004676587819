import React from 'react';
import './MoneyLoan.css';
import { useInView } from 'react-intersection-observer';
import MoneyLoanBlock from '../MoneyLoanBlock/MoneyLoanBlock';

const fadeInLeft = 'animate__animated animate__fadeInLeft';
const fadeInRight = 'animate__animated animate__fadeInRight';

const MoneyLoan = ({ LoanTypes }) => {
  const [ref, inView] = useInView({});
  const [ref2, inView2] = useInView({});
  const [ref3, inView3] = useInView({});
  const [ref4, inView4] = useInView({});

  return (
    <div className="money_loan_plot">
      <div ref={ref}>
        <MoneyLoanBlock
          loanItem={LoanTypes.item1}
          inView={inView}
          fadeInLeft={fadeInLeft}
          fadeInRight={fadeInRight}
        ></MoneyLoanBlock>
      </div>
      <div ref={ref2}>
        <MoneyLoanBlock
          loanItem={LoanTypes.item2}
          inView={inView2}
          blockClass="loanWrapper"
          fadeInLeft={fadeInRight}
          fadeInRight={fadeInLeft}
        ></MoneyLoanBlock>
      </div>
      <div ref={ref3}>
        <MoneyLoanBlock
          loanItem={LoanTypes.item3}
          inView={inView3}
          fadeInLeft={fadeInLeft}
          fadeInRight={fadeInRight}
        ></MoneyLoanBlock>
      </div>
      {/* <div ref={ref4}>
        <MoneyLoanBlock
          loanItem={LoanTypes.item4}
          inView={inView4}
          blockClass="loanWrapper"
          fadeInLeft={fadeInRight}
          fadeInRight={fadeInLeft}
        ></MoneyLoanBlock>
      </div> */}
    </div>
  );
};

export default MoneyLoan;
