import React from 'react';
import DGVIcon from '../../assets/images/DGVIcon.png';
import LinkeDin from '../../assets/images/linkedin 1.svg';
import twitter from '../../assets/images/twitter 1.svg';
import youTube from '../../assets/images/Combined-Shape.svg';
import PCI from '../../assets/images/PCI.png';
import { FooterData } from '../../utils/constants';
import privacyPolicyDgv from '../../assets/documents/DgvPrivacyPolicy.pdf';
import privacyPolicyLending from '../../assets/documents/PrivacyPolicyLending.pdf';
import termsConditions from '../../assets/documents/TermsCondition.pdf';
import { useNavigate, Link } from 'react-router-dom';
import '../Footer/Footer.css';

const Footer = () => {
  let navigate = useNavigate();

  const onMenuClick = (listItem) => {
    if (listItem.linkTo) {
      navigate(listItem.linkTo);
      document.getElementById('hamburgerChk').checked = false;
    }
  };

  const onSubMenuClick = (event, listItem) => {
    event.stopPropagation();
    if (listItem.title === 'Privacy Policy') {
      window.open(privacyPolicyDgv);
    } else if (listItem.title === 'Privacy Policy Lending') {
      window.open(privacyPolicyLending);
    } else if (listItem.title === 'Terms & Conditions') {
      window.open(termsConditions);
    } else {
      navigate(listItem.linkTo);
    }
  };

  return (
    <>
      <div className="footer">
        <div className="footer-wrap">
          <section className="footer-container socialMedia">
            <div className="footer-col-logo socialMedia ">
              <div className="dgvIcon">
                <img className="redImg" src={DGVIcon} alt="img" />
              </div>
              <div className="footer-subtitles ">
                India's First Integrated Dairy<br></br> Fintech & Marketplace Platform
              </div>
              <div className="socialMedia">
                <a
                  href="https://www.linkedin.com/company/neobharatbanking/"
                  className="social-icon mr-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkeDin} alt="Linked in" />
                </a>

                <a href="https://twitter.com/dgvwings" target="_blank" rel="noreferrer" className="social-icon mr-1">
                  <img src={twitter} alt="Twitter" />
                </a>

                <a
                  href="https://www.youtube.com/channel/UC8--WZaJ9ZxSh9C-jU8zifw "
                  className="social-icon mr-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youTube} alt="YouTube" />
                </a>
              </div>
              <div className="PCI">
                <img src={PCI} alt="PCI" />
              </div>
            </div>
            <div className="footer-col-wrapper">
              {FooterData.map((footerLink) => (
                <div className="footer-col" key={footerLink.id}>
                  <div
                    className="footer-link-our-network"
                    onClick={() => onMenuClick(footerLink)}
                    style={{ cursor: 'pointer' }}
                  >
                    <h4 className="footer-head">{footerLink.title}</h4>
                  </div>
                  <ul className="list" style={{ cursor: 'pointer' }}>
                    {footerLink?.subItems?.map((link, index) => {
                      return (
                        <div key={link.id}>
                          <Link to={link?.linkTo}>
                            <li className={link.class} onClick={(event) => onSubMenuClick(event, link)}>
                              {link.title}
                            </li>
                          </Link>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </section>
          <div className="copyright">
            <p className="allRights">Copyright © Digivriddhi Technologies Private Limited 2023</p>

            <p className="allRights"> All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
