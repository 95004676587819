import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '../../assets/images/close-icon.png';
import './DialogVideo.css';

export const DialogVideo = ({ title, link, handleClose }) => {
  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <img src={CloseIcon} alt="closeIcon" className="close-icon" onClick={handleClose}></img>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="video-container">
          <iframe
            width="352"
            height="198"
            src={link}
            title="Launch of DGV Platform at Sarhad Dairy (Kutch)"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
