import './LifeAtDGVInside.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';
import { Life_At_DGV_Data } from '../../utils/constants';

const LifeAtDGVInside = () => {
  const [isClickCeleb, setIsClickCeleb] = useState(false);
  const [celebImg, setCelebImg] = useState([]);
  const [indexValue, setIndexValue] = useState(0);

  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width:1024px)').matches);

  useEffect(() => {
    window.matchMedia('(min-width: 1024px)').addEventListener('change', (e) => setIsDesktop(e.matches));
  }, []);

  const popup = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    adaptiveHeight: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 860,
        settings: {
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const popUpHandler = (allImg, index) => {
    let startImg = [];
    let allImgData = [];

    for (let i = 0; i < allImg.length; i++) {
      if (i < index + indexValue) {
        startImg.push(allImg[i]);
      } else {
        allImgData.push(allImg[i]);
      }
    }
    allImgData.push(...startImg);

    setIsClickCeleb(true);
    setCelebImg([...allImgData]);
  };

  return (
    <div>
      {/* -------------POPUP SLIDER------------ */}
      <div
        className={isClickCeleb ? 'celebration-img-popup' : 'celeb-close'}
        style={{ display: isClickCeleb ? 'block' : 'none' }}
      >
        <div
          className="cancel-button"
          onClick={() => {
            setCelebImg([]);
            setIndexValue(1);
            setIsClickCeleb(false);

            document.body.style.overflow = 'unset';
          }}
        >
          <img className="cancel-popup" src={require('./images/cancel-popup.png')} />
        </div>

        <Slider className="popup-image" {...popup}>
          {celebImg.map((item, index) => {
            return (
              <div className="slick-image-popup" key={index}>
                <img src={require(`./images/${item.img}`)} alt="DGV_image" />
              </div>
            );
          })}
        </Slider>
      </div>

      {/* ---------------------------- */}

      <div className="life-at-DGV-inside-section">
        <div className="life-at-dgv-inside-wrapper">
          <h1 className="life-at-dgv-inside-heading">Life at DGV</h1>
        </div>

        {Life_At_DGV_Data?.map((item, index) => {
          return (
            <div className="celebration-section" key={index} id={item.id}>
              <div className="life-at-dgv-celebration-wrapper">
                <h2 className="celebration-section-title">{item.title}</h2>
                <div className="celebration-slider">
                  <Slider {...settings}>
                    {item.images.map((itemData, i) => {
                      return (
                        <div
                          className="slick-image-carosal"
                          key={i}
                          onClick={() => {
                            document.body.style.overflow = 'hidden';
                            popUpHandler(item.images, i);
                          }}
                        >
                          {isDesktop ? (
                            <div className="lad-img-web">
                              <img
                                className="life-at-dgv-inside-slick-image-web"
                                src={require(`./images/${itemData.img}`)}
                                alt="DGV_image"
                              />
                            </div>
                          ) : (
                            <div className="lad-img-mob">
                              <img
                                className="life-at-dgv-inside-slick-image-mob"
                                src={require(`./images/${itemData.mobImg}`)}
                                alt="DGV_image"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LifeAtDGVInside;
