const validateCategory = (value, errors) => {
  if (Object.keys(value).includes('category')) {
    if (!value.category) {
      errors.category = 'Select category';
    } else {
      delete errors['category'];
    }
  }
};

const validateName = (value, errors, nameReg) => {
  console.log('validate', !value?.name);
  if (Object.keys(value).includes('fullName')) {
    if (!value?.fullName) {
      errors.fullName = 'Name is required';
    } else if (!value.fullName.match(nameReg)) {
      errors.fullName = 'Only [a-zA-Z] letters';
    } else {
      delete errors['fullName'];
    }
  }
};

const validatefName = (value, errors, nameReg) => {
  console.log('validate', !value?.name);
  if (Object.keys(value).includes('name')) {
    if (!value.name) {
      errors.name = 'Name is required';
    } else if (!value.name.match(nameReg)) {
      errors.name = 'Only [a-zA-Z] letters';
    } else {
      delete errors['fullName'];
    }
  }
};

const validateEmail = (value, errors) => {
  if (Object.keys(value).includes('email')) {
    if (!value.email) {
      errors.email = 'Email is required';
    } else if (value.email && !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(value.email)) {
      errors.email = 'Email is Invalid';
    } else {
      delete errors['email'];
    }
  }
};

const validateSubject = (value, errors) => {
  if (Object.keys(value).includes('subject')) {
    if (!value.subject) {
      errors.subject = 'Subject is required';
    } else if (value?.subject?.length > 250) {
      errors.subject = '250 characters should be the maximum';
    } else {
      delete errors['subject'];
    }
  }
};

const validateMessage = (value, errors) => {
  if (Object.keys(value).includes('message')) {
    if (!value.message) {
      errors.message = 'Message is required';
    } else if (value?.message?.length > 1000) {
      errors.message = '1000 characters should be the maximum';
    } else {
      delete errors['message'];
    }
  }
};

const validateMobileNo = (value, errors, phoneNo) => {
  if (Object.keys(value).includes('mobileNo')) {
    if (!value?.mobileNo) {
      errors.mobileNo = 'Mobile is required';
    } else if (!value?.mobileNo.match(phoneNo)) {
      errors.mobileNo = 'Please enter 10 digit valid number';
    } else {
      delete errors['mobileNo'];
    }
  }
  // console.log('validateMobileNo', value, errors, phoneNo);
};

const validatePinCode = (value, errors, pincodereg) => {
  if (Object.keys(value).includes('pincode')) {
    if (!value.pincode) {
      errors.pincode = 'Pincode is required';
    } else {
      console.log('pincode', pincodereg, value?.pincode?.match(pincodereg));
      if (value?.pincode?.match(pincodereg)) {
        delete errors['pincode'];
      } else {
        errors.pincode = 'Please enter valid pincode';
      }
      // else {
      //   if (value?.pincode?.length > 6 || value?.pincode?.length < 6) {
      //     errors.pincode = 'Please enter 6 digit pincode';
      //   }
      //   else {
      //     delete errors['pincode'];
      //   }
      // }
    }
  }

  // console.log('pincode', value?.pincode?.length);
};

const validateFile = (value, errors) => {
  if (Object.keys(value).includes('file')) {
    const MAX_FILE_SIZE = 5120; // 5MB
    if (value.file) {
      const fileSize = value.file.size / 1024;

      if (fileSize > MAX_FILE_SIZE) {
        errors.file = 'Please upload file size upto 5MB';
      } else {
        delete errors['file'];
      }
    } else {
      errors.file = 'Please upload file';
    }
  }
};

export const Validate = (value) => {
  let errors = {};
  const phoneNo = /^\d{10}$/;
  const nameReg = /^[a-zA-Z ]*$/;
  const pincodereg = /^[1-9]{1}[0-9]{5}$/;

  validateCategory(value, errors);
  validateName(value, errors, nameReg);
  validateEmail(value, errors);
  validateSubject(value, errors);
  validateMessage(value, errors);
  validateMobileNo(value, errors, phoneNo);
  validateFile(value, errors);
  validatePinCode(value, errors, pincodereg);
  validatefName(value, errors, nameReg);

  return errors;
};
