import React from 'react';
import FormButton from '../FormComponents/FormButton/FormButton';
import './CareersApplyJob.css';
const CareersApplyJob = ({ showDropDownApp }) => {
  return (
    <div className="carrers-first-apply-container">
      <div className="careers-firstApply-title">We're always looking for enthusiastic, talented people!</div>
      <div className="careers-firstApply-Secondtitle">
        Come join our team of experts and accelerate your career the way you want to.
      </div>
      <div className="apply-carres-btn">
        <FormButton title="Apply Now" handleSubmit={() => showDropDownApp()} />
      </div>
    </div>
  );
};

export default CareersApplyJob;
