import React from 'react';
import linkedIn from '../../assets/images/linkedin.png';
import './CoreManagementTeam.css';

const CoreManagementTeam = () => {
  const core = [
    {
      img: 'Pushkar_Salunkhe.png',
      name: ' Pushkar Salunkhe ',
      post1: 'Chief ',
      post2: 'Technology Officer',
      linkedin: 'https://www.linkedin.com/in/pushkar-salunkhe-9a1b1a7/',
    },
    {
      img: 'Mukesh_Ranjan.png',
      name: 'Mukesh Ranjan',
      post1: 'Chief',
      post2: 'Business Officer - SaaS',
      linkedin: 'https://www.linkedin.com/in/mukesh-ranjan-77904825/ ',
    },
    {
      img: 'Nishchint_Paliwal.png',
      name: 'Nishchint Paliwal',
      post1: 'Chief',
      post2: 'Commercial Officer',
      linkedin: 'https://www.linkedin.com/in/nishchint-paliwal-318161b6/ ',
    },
    {
      img: 'Romil_Gupta.png',
      name: 'Romil Gupta',
      post1: 'Head- Distribution',
      post2: '& Risk',
      linkedin:
        'https://www.linkedin.com/in/romil-gupta-4089aa21?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ',
    },
    // {
    //   img: 'Mayank_Rai.png',
    //   name: 'Mayank Rai',
    //   post1: 'GM & Head Business',
    //   post2: 'DGV Pay & Money',
    //   // linkedin: ' https://www.linkedin.com/in/mayank-rai-a5980214/',
    // },

    // {
    //   img: 'Ravi_Singh.png',
    //   name: 'Ravi Singh',
    //   post1: 'GM & Head - People,',
    //   post2: 'Facilities & Marketing',
    //   linkedin: 'https://www.linkedin.com/in/ravi-singh-8417498/',
    // },
    // {
    //   img: 'Avdhoot_Bhogulkar.png',
    //   name: 'Avdhoot Bhogulkar',
    //   post1: 'GM & Head - IT Infra &',
    //   post2: 'Information Security',
    //   linkedin: 'https://www.linkedin.com/in/avdhoot-bhogulkar-70a89688/',
    // },
    {
      img: 'vishal_puri.png',
      name: 'Vishal Puri',
      post1: 'Head - DGV Connect',
      post2: '& Marketing',
      linkedin: 'https://www.linkedin.com/in/vishal-puri-67706997/',
    },
    {
      img: 'Surya_Reddy.png',
      name: 'P Surya Naryana Reddy',
      post1: 'Head - Operations',
      post2: '',
      linkedin:
        'https://www.linkedin.com/in/suryanarayana-reddy-p-60b5a4103?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    {
      img: 'Sangeetha_Lewis.png',
      name: 'Sangeetha Lewis',
      post1: 'AVP, Banking & Dairy',
      post2: '',
      linkedin: 'https://www.linkedin.com/in/sangeeta-lewis/',
    },
    // {
    //   img: 'Vijay_Chougle.png',
    //   name: 'Vijay Chougle',
    //   post1: 'Deputy Vice President -',
    //   post2: 'Operations',
    //   linkedin: 'https://www.linkedin.com/in/vijay-chougule-8ab24910/',
    // },
    // {
    //   img: 'Rohit_Mahakal.png',
    //   name: 'Rohit Mahakal',
    //   post1: 'Finance',
    //   post2: 'Controller',
    //   linkedin: ' https://www.linkedin.com/in/rohit-mahakal-bb495718/ ',
    // },
    // {
    //   img: 'Mayur_Raval.png',
    //   name: 'Mayur Raval',
    //   post1: 'National Head -',
    //   post2: 'Dairy Relations',
    //   linkedin: 'https://www.linkedin.com/in/mayur-raval-677a96140/',
    // },
  ];

  return (
    <section className="core-management-wrapper">
      <div className="title">Core Management Team</div>
      <div className="core_team_main">
        {core.map(({ linkedin, post1, post2, name, img }, i) => {
          return (
            <div className="team_member_plot" key={i}>
              <div className="team_member" key={i}>
                <img src={require(`../../assets/images/${img}`)} alt="core_team_member" />
                <a className="linkedIn_img" href={linkedin} target="_blank" rel="noreferrer">
                  <img src={linkedIn} alt="linkedIn_icon" />
                </a>
              </div>
              <div>
                <div className="member_name">{name}</div>
                <div className="designation">
                  {post1} <br />
                  {post2}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CoreManagementTeam;
