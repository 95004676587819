import React, { useState } from 'react';
import FormTextInput from '../FormComponents/FormTextInput/FormTextInput';
import FormTextArea from '../FormComponents/FormTextArea/FormTextArea';
import FormFileInput from '../FormComponents/FormFileInput/FormFileInput';
import FormButton from '../FormComponents/FormButton/FormButton';
import CloseButton from '../FormComponents/CloseButton/CloseButton';
import { Validate } from '../FormValidation/FormValidation';
import DropDown from '../FormComponents/DropDown/DropDown';
import ErrorIcon from '../../assets/images/errorIcon.png';
import { BASE_URL } from '../../utils/api';
import './JobPopUp.css';

export default function JobPopUp({
  showJobTitle = '',
  spinnerPopUp,
  handleFormSubmit,
  handleClose,
  handleFormFailure,
}) {
  const [user, setUser] = useState({
    fullName: '',
    email: '',
    mobileNo: '',
    message: '',
    file: '',
    category: showJobTitle,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('name,value', name, value);
    setUser({ ...user, [name]: value });
  };

  const handleFileChange = (file) => {
    setUser({ ...user, file });
  };

  const submitDetails = (user) => {
    const formData = new FormData();
    formData.append('file', user.file);
    formData.append('fullName', user.fullName);
    formData.append('email', user.email);
    formData.append('mobileNo', user.mobileNo);
    formData.append('description', user.message);
    formData.append('category', user.category);

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    fetch(`${BASE_URL}career`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'OK') {
          handleFormSubmit(result);
        } else {
          handleFormFailure();
        }
      })
      .catch((error) => handleFormFailure());
  };

  const clearUserData = () => {
    setUser({
      fullName: '',
      email: '',
      mobileNo: '',
      message: '',
      file: '',
      category: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidData = Validate(user);
    if (Object.keys(isValidData).length === 0) {
      spinnerPopUp();
      submitDetails(user);
      console.log(user);
      clearUserData();
      setErrors('');
    } else {
      setErrors(isValidData);
    }
  };

  return (
    <div className="_job_popup">
      <div className="job-sub-title">
        {showJobTitle && (
          <div>
            <div>You are applying for</div>
            <div className="job-title">{showJobTitle}</div>
          </div>
        )}
        <CloseButton class={'_close'} handleClose={handleClose} />
      </div>

      <form className="_form_plot">
        {!showJobTitle && (
          <div className="form-text-field">
            <div className="label">Area Of Expertise *</div>
            <DropDown onChange={handleChange} value={user.category} name={'category'}></DropDown>
            {errors.category ? (
              <div className="errorText">
                <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                {errors?.category}
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        <div className="form-text-field">
          <div className="label">Your Full Name *</div>
          <FormTextInput
            onChange={handleChange}
            value={user.fullName}
            name={'fullName'}
            placeholder="Enter Full Name *"
            type="text"
          />
          {errors.fullName ? (
            <div className="errorText">
              <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
              {errors?.fullName}
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="form-text-field">
          <div className="label">EMail ID *</div>
          <FormTextInput
            onChange={handleChange}
            value={user.email}
            name={'email'}
            placeholder="John@Example.com"
            type="text"
          />
          {errors.email ? (
            <div className="errorText">
              <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
              {errors?.email}
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="form-text-field">
          <div className="label">Contact Number *</div>
          <FormTextInput
            onChange={handleChange}
            value={user.mobileNo}
            name={'mobileNo'}
            placeholder="Mobile Number*"
            type="number"
          />
          {errors.mobileNo ? (
            <div className="errorText">
              <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
              {errors?.mobileNo}
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="form-text-field">
          <div className="label">Description *</div>
          <FormTextArea
            onChange={handleChange}
            value={user.message}
            name={'message'}
            placeholder="Tell us a little about your self"
          />
          {errors.message ? (
            <div className="errorText">
              <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
              {errors?.message}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="form-text-field">
          <FormFileInput handleFileChange={handleFileChange} name={'file'} value={user.file} />
          {errors.file ? (
            <div className="errorText">
              <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
              {errors?.file}
            </div>
          ) : (
            ''
          )}
        </div>
        <FormButton title="Submit" handleSubmit={handleSubmit} />
      </form>
    </div>
  );
}
