import React from 'react';
import Contact_us_line from '../../assets/images/vertical-line.png';
import Contact_us_hori_line from '../../assets/images/hori-line.png';
import Call_icon from '../../assets/images/Call-Icon.png';
import Email_Icon from '../../assets/images/Email-Icon.png';
import { OfficeAddress, ContactUsEmails, ContactUsNumber } from '../../utils/constants';
import './VisitUs.css';

const VisitUs = () => {
  return (
    <>
      <div></div>
      <div className="visit-us">
        <div className="visit-us-header">
          <div className="visit-us-head">Visit Us</div>
        </div>
        <div className="visit-us-wrapper">
          <div className="offices">
            {OfficeAddress.map((add, i) => {
              return (
                <div key={add.id} className="office-address-info">
                  <div className="Office-header">{add.officeType}</div>
                  <div className={add.className}>{add.address}</div>
                  <div className="location-contain">
                    <div className="Location-icon">
                      <img src={add.locationIcon} alt="location-img" />
                    </div>
                    <div className="viwe-on-map">
                      <a href={add.mapLink} target="_blank" rel="noreferrer">
                        {add.viewOnMap}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="Contact_us_hori_line">
            <img src={Contact_us_hori_line} alt="line" />
          </div>
          <div className="contact-medium">
            <div className="contact-medium-wrapper">
              <div className="call-center">
                <div className="mail-icon-background">
                  <div className="call-img">
                    <img src={Email_Icon} alt="phone Icon" />
                  </div>
                </div>
                <div className="call-center-text">Contact Us</div>
              </div>
              <div className="number">
                <div className="number-one">{ContactUsNumber.callCenterOne}</div>
              </div>
            </div>
            <div className="Contact-us-line-call">
              <img src={Contact_us_line} alt="line" />
            </div>
            <div className="contact-medium-wrapper">
              <div className="call-center">
                <div className="mail-icon-background">
                  <div className="call-img">
                    <img src={Email_Icon} alt="mail Icon" />
                  </div>
                </div>
                <div className="call-center-text">EMAIL ID</div>
              </div>
              <div>
                <div className="emailid">
                  <span>Principal Nodal Officer:</span>
                  {ContactUsEmails.principalNobalOfficer}
                </div>
                <div className="join-us">
                  <span>Join Us:</span> {ContactUsEmails.joinUs}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitUs;
