import React from 'react';
import './FormTextInput.css';

export default function FormTextInput({ onChange, placeholder, name, label, type, value, error, fClass }) {
  return (
    <div className="_form_input_row">
      {label !== '' && <div className="label">{label}</div>}
      <input
        type={type}
        className={fClass ? 'fClass' : '_text_box'}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        maxLength={type === 'tel' && name === 'mobileNo' ? 10 : ''}
      />
      {error}
    </div>
  );
}
