import React from 'react';
import { Route, Routes as ReactRouter } from 'react-router-dom';
import Home from './pages/Home/Home';
import DGVConnect from './pages/Products/DGVConnect';
import { ROUTES_PATH } from './utils/constants';
import DGVPay from './pages/Products/DGVPay';
import DGVMoney from './pages/Products/DGVMoney';
import MainLayout from './layout/MainLayout';
import LandingPageLayout from './layout/LandingPageLayout';
import App from './App';
import ContactUs from './pages/Company/ContactUs';
import Careers from './pages/Company/Careers';
import OurNetwork from './pages/OurNetwork/OurNetwork';
import AboutUs from './pages/Company/AboutUs/AboutUs';
import Solution from './pages/Solutions/Solution';
import LifeAtDGVInside from './pages/LifeAtDGVInside/LifeAtDGVInside';
import MediaEvents from './pages/MediaEvents/MediaEvents';
import MDE from './pages/Solutions/MDE';

const Routes = () => {
  return (
    <ReactRouter>
      <Route element={<App />}>
        {/* <Route path={ROUTES_PATH.DGV_CONNECT} element={<ConnectQRcode />}></Route> */}
        <Route element={<MainLayout />}>
          {/* <Route path={ROUTES_PATH.NETWORK} element={<Network />} /> */}
          <Route path={ROUTES_PATH.CAREERS} element={<Careers />} />
          <Route path={ROUTES_PATH.NETWORK} element={<OurNetwork />} />
          {/* <Route path={ROUTES_PATH.COMPANY} element={<Company />} /> */}
          <Route path={ROUTES_PATH.CONTACTUS} element={<ContactUs />} />
          <Route path={ROUTES_PATH.ABOUT_US} element={<AboutUs />} />
          <Route path={ROUTES_PATH.MEDIA_EVENTS} element={<MediaEvents />} />

          <Route path={ROUTES_PATH.SOLUTIONS} element={<Solution />} />
          <Route path={ROUTES_PATH.MicroDairyEnterprise} element={<MDE />} />
          <Route path={ROUTES_PATH.LIFE_AT_DGV} element={<LifeAtDGVInside />} />
        </Route>
        <Route path={ROUTES_PATH.PAY} element={<DGVPay />} />
        <Route path={ROUTES_PATH.MONEY} element={<DGVMoney />} />
        <Route path={ROUTES_PATH.CONNECT} element={<DGVConnect />} />

        <Route element={<LandingPageLayout />}>
          <Route index path={ROUTES_PATH.HOME} element={<Home />} />
        </Route>
      </Route>
    </ReactRouter>
  );
};

export default Routes;
