import React from 'react';

const AreaOfExpertise = [
  'Product Development',
  'Technology',
  'Business Development and Sales',
  'Back Office(HR,Legal,Finance,Admin)',
  'Operations',
];

const DropDown = ({ onChange, label, name, value, error }) => {
  return (
    <div className="_form_input_row">
      {label && <div className="label">{label}</div>}
      <select className="_text_box" onChange={onChange} name={name} value={value}>
        <option value="">Select</option>
        {AreaOfExpertise.map((area, i) => (
          <option key={i + 1} value={area}>
            {area}
          </option>
        ))}
      </select>
      {error}
    </div>
  );
};

export default DropDown;
