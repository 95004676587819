import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import MDEPopup from '../components/MDEPopup';
import { useEffect } from 'react';

const LandingPageLayout = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowModal(true), 5000);
  }, []);

  return (
    <div className=" desktop-view">
      <Header />
      {/* <MDEPopup setShowModal={setShowModal} showModal={showModal} /> */}
      <div>{children ? children : <Outlet />}</div>
    </div>
  );
};

export default LandingPageLayout;
