import React from 'react';
import './mdesolutions.css';
import Bullets from '../../assets/images/mde-bullets.png';
import play from '../../assets/images/play-video.png';
import VideoButton from '../VideoButton/VideoButton';

export default function MdeSolutions() {
  const bulletPoints = [
    {
      id: 1,
      point: 'Stitching all components of Dairy Ecosystem under one roof.',
    },
    {
      id: 2,
      point: 'Bringing Doorstep Service for setting up of Dairy Farm and Dairy Plant.',
    },
    {
      id: 3,
      point: 'Easy availability of Loan and Insurance from Leading Banking and Insurance Partner',
    },
  ];
  return (
    <div className="_mdesolutions_plot">
      <div className="solutions_title">Customizing end-to-end solution in setting up of Dairy Farm</div>
      <div className="solutions_points">
        {bulletPoints?.map((points, p) => {
          return (
            <div className="solutions_list">
              <img src={Bullets} className="bullets" alt="bullets" />
              <p className="bullets-text">{points?.point}</p>
            </div>
          );
        })}
      </div>
      {/* <button className="mde_button">
        <div className="_play-icon">
          <img src={play} alt="play" className="play" />
        </div>
        <p className="text-watch">Watch Now</p>
      </button> */}
      {/* <VideoButton titles="" handleClickOpen={''}></VideoButton> */}
      {/* <p className="lang-text">{btn.language} /> */}
    </div>
  );
}
