import React from 'react';
import { useInView } from 'react-intersection-observer';
import './VisionMission.css';
const VisionMission = () => {
  const [ref, inView] = useInView();

  return (
    <>
      <div className="vision-mission-main">
        <div className={inView ? 'vision-mission animate__animated animate__fadeInLeft' : 'vision-mission'} ref={ref}>
          <div className="vision">
            <img className="vision-img" src={require('../../assets/images/Vision-icon.png')} alt="vissionImg" />
            <div className="vision-head">Our Vision</div>
            <div className="vision-para">
              Bringing world-class financial services & integrated marketplace at the doorstep of every Indian farmer
            </div>
          </div>

          <div className="vision">
            <img className="mission-img" src={require('../../assets/images/Mission-icon.png')} alt="missionImg" />
            <div className="vision-head">Our Mission</div>
            <div className="vision-para">
              To empower India’s 80M Dairy farmers & Dairy micro-enterprises with bespoke Banking, Financial Service and
              Insurance products, to enhance their productivity with an integrated partners in growth and marketplace
              with a view to becoming their lifelong partner in their growth and prosperity.
            </div>
          </div>
        </div>
        <div className={inView ? 'pay-machine animate__animated animate__fadeInRight' : 'pay-machine'}>
          <img src={require('../../assets/images/Pay-machin-about-us.png')} />
        </div>
      </div>
    </>
  );
};

export default VisionMission;
