import React, { useState } from 'react';
import './MediaEvents.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MediaAndEvents from '../../components/MediaEvents/MediaAndEvents';
import { MEDIA_EVENT_INFO, MEDIA_EVENT_SLIDER_IMAGES } from '../../utils/constants';
import ProductInfoPlot from '../../components/ProductInfoPlot/ProductInfoPlot';

const MediaEvents = () => {
  const [isClickCeleb, setIsClickCeleb] = useState(false);
  const [celebImg, setCelebImg] = useState([]);

  const popup = {
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
  };
  return (
    <>
      <div
        className={isClickCeleb ? 'celebration-img-popup' : 'celeb-close'}
        style={{ display: isClickCeleb ? 'block' : 'none' }}
      >
        <div
          className="cancel-button"
          onClick={() => {
            setCelebImg([]);
            setIsClickCeleb(false);

            document.body.style.overflow = 'unset';
          }}
        >
          <img className="cancel-popup" src={require('./images/cancel-popup.png')} alt="cancel popUp" />
        </div>

        <Slider className="popup-image" {...popup}>
          {celebImg.map((item, index) => {
            return (
              <div className="slick-image-popup" key={index}>
                <img src={require(`../../components/MediaEvents/images/${item.img}`)} alt="DGV_image" />
              </div>
            );
          })}
        </Slider>
      </div>

      <div className="media-page-padding">
        <ProductInfoPlot images={MEDIA_EVENT_SLIDER_IMAGES} info={MEDIA_EVENT_INFO} />
      </div>

      <div className="media-page-accordion">
        <MediaAndEvents setIsClickCeleb={setIsClickCeleb} setCelebImg={setCelebImg} />
      </div>
    </>
  );
};

export default MediaEvents;
