import React from 'react';
import Slider from 'react-slick';
import { STRATIGIC_PARTNER } from '../../utils/constants';
import './StrategicPartners.css';
const StrategicPartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    arrow: false,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnDotsHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div className="StrategicPartner-carosal">
        <div className="strategic-partner-heading">Dairy Partners</div>
        <Slider {...settings}>
          {STRATIGIC_PARTNER.map((item) => {
            return (
              <div key={item.id} className="StrategicPartner-img">
                <img src={item.path} alt="partner" />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default StrategicPartners;
