import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  fade: true,
  arrows: false,
  infinite: true,
  speed: 3000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Carousel = ({ images = [] }) => {
  return (
    <div className="slider br-radius">
      <Slider {...settings}>
        {images.map((item) => (
          <div key={item.id}>
            <img alt={item.name}  src={item.path} className="carl-img" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
