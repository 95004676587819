import React, { useEffect, useState } from 'react';
import gujratMap from '../../assets/images/gujarat_map.png';
import indiaMapWithGujarat from '../../assets/images/india_map_with_gujarat.png';
import { useInView } from 'react-intersection-observer';
import { NETWORK_PRESENCE_DATA, NETWORK_STATE_PRESENCE } from '../../data/networkPresence';
import { BASE_URL } from '../../utils/api';
import './DataPresence.css';

const DataPresence = () => {
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    threshold: 0.4,
  });

  const [data, setData] = useState(NETWORK_PRESENCE_DATA);
  const [networkData, setNetworkData] = useState(NETWORK_STATE_PRESENCE);

  useEffect(() => {
    fetch(`${BASE_URL}ourNetworkGet`)
      .then((res) => res.json())
      .then((response) => {
        const network = response.data;

        setNetworkData(network);
        const arr = [
          {
            img: 'milk_union.png',
            des: 'No. of Union',
            num: `${network.dairyUnionCount}`,
            delay: '1s',
          },
          {
            img: 'milk_car.png',
            des: 'No. of Society',
            num: `${network.noOfSociety}`,
            delay: '2s',
          },
          {
            img: 'cow.png',
            des: 'No. of Bovines Listed',
            num: `${network.listedCattleCount}`,
            delay: '3s',
          },
          {
            img: 'farmer.png',
            des: 'No. of farmers',
            num: `${network.noOfFarmers}`,
            delay: '4s',
          },
          {
            img: 'rupee.png',
            des: 'Loan Distributed (₹)',
            num: `${network.loanDisbursedAmount}`,
            delay: '5s',
          },
        ];
        setData(arr);
      });
  }, []);

  return (
    <div className="data-presence">
      <div className="container">
        <div className="heading">
          <h1>Our Presence</h1>
          <p>
            We have partnered with <span>{networkData?.dairyUnionCount} dairy unions</span> out of a total of{' '}
            <span>{networkData?.totalDairyUnion} dairy unions</span> in <span>{networkData?.stateName}</span>
          </p>
        </div>
        <div className="data-presence-wrapper">
          <div className={inView ? 'gujrat-img animate__animated animate__zoomIn ' : 'gujrat-img'} ref={ref}>
            <img src={indiaMapWithGujarat} alt="Gujrat Map" />
          </div>

          <div ref={ref2} className="data-box">
            {data.map((item, i) => {
              return (
                <div
                  className={
                    inView2
                      ? `partner-data animate__animated animate__fadeInRight animate__delay-${item.delay} `
                      : 'partner-data'
                  }
                  key={i}
                >
                  <div className="icon-img">
                    <img src={require(`../../assets/images/${item.img}`)} alt="icon" />
                  </div>
                  <div className="data-head">{item.des}</div>
                  <div className="count">{item.num}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPresence;
