import React from 'react';
import ContactWrapper from '../ContactWrapper';
import Menu from '../Menu/Menu';
import { MENU_ITEMS, ROUTES_PATH } from '../../utils/constants';
import LOGO from '../../assets/images/DGV.png';
import WhiteLogo from '../../assets/images/DGV_White_LOGO.png';
import HamburgerLogo from '../../assets/images/hamburgerLines.png';
import HamburgerClose from '../../assets/images/hamburgerClose.png';

import './Header.css';

const Header = () => {
  const hambugerHandler = (event) => {
    const { checked } = event.target;

    if (checked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };
  return (
    <nav className="header-navbar lato-400 LineHeight19">
      <div className="logo-image">
        <a href={`#${ROUTES_PATH.HOME}`}>
          {' '}
          <img src={LOGO} alt="DGV LOGO" className="logo"></img>{' '}
        </a>
        <a href={`#${ROUTES_PATH.HOME}`}>
          <img src={WhiteLogo} alt="DGV white logo" className="MobileLogo"></img>
        </a>
      </div>
      <div className="info-container">
        <div className="contact-info">
          <ContactWrapper></ContactWrapper>
        </div>
        <input className="checkbox" type="checkbox" id="hamburgerChk" onChange={hambugerHandler} />
        <img src={HamburgerLogo} alt="hamburgerLogo" className="hamburger-lines hamburger-lines-logo" />
        <img src={HamburgerClose} alt="hamburgerClose" className="hamburger-lines hamburgerClose" />
        <Menu menuItems={MENU_ITEMS} />
      </div>
    </nav>
  );
};

export default Header;
