import React from 'react';
import './mdeofferings.css';
import Brochure from '../../assets/images/MDEOfferings/MDE_Brochure.pdf';
import FormButton from '../FormComponents/FormButton/FormButton';
import OfferingsCircles from './OfferingsCircles/OfferingsCircles';
import locationPng from '../../assets/images/MDEOfferings/offerings-location.png';
import designPng from '../../assets/images/MDEOfferings/offering-Design.png';
import civilInfra from '../../assets/images/MDEOfferings/offering-CivilInfrastructure.png';
import Dairy from '../../assets/images/MDEOfferings/offering-dairy.png';
import Breed from '../../assets/images/MDEOfferings/offerings-bovineBreed.png';
import Transportation from '../../assets/images/MDEOfferings/offerings-transportation.png';
import Nutrition from '../../assets/images/MDEOfferings/offerings-nutrition.png';
import MilkRoutine from '../../assets/images/MDEOfferings/offerings-milkingRoutine.png';
import HyginePrac from '../../assets/images/MDEOfferings/offerings-hygienePractices.png';
import VerServ from '../../assets/images/MDEOfferings/offeringsvetServices.png';
import SemenAss from '../../assets/images/MDEOfferings/offerings-semenAssistance.png';
import LoanAss from '../../assets/images/MDEOfferings/offerings-loanAssistance.png';
import BovineMark from '../../assets/images/MDEOfferings/offeringsbovineMarketplace.png';
import Insurance from '../../assets/images/MDEOfferings/offerings-insurance.png';

const MDEOfferings = () => {
  const circlesObject = [
    {
      circleImage: locationPng,
      heading: 'Location',
    },
    {
      circleImage: designPng,
      heading: 'Design',
    },
    {
      circleImage: civilInfra,
      heading: 'Civil <br>Infrastructure',
    },
    {
      circleImage: Dairy,
      heading: 'Dairy<br> Infrastructure',
    },
    {
      circleImage: Breed,
      heading: 'Bovine Breed',
    },
    {
      circleImage: Transportation,
      heading: 'Transportation',
    },
    {
      circleImage: Nutrition,
      heading: 'Nutrition',
    },
    {
      circleImage: MilkRoutine,
      heading: 'Milking <br>Routine',
    },
    {
      circleImage: HyginePrac,
      heading: 'Hygiene<br> Practices',
    },
    {
      circleImage: VerServ,
      heading: 'Vet<br> Services',
    },
    {
      circleImage: SemenAss,
      heading: 'Semen<br> Assistance',
    },
    {
      circleImage: LoanAss,
      heading: 'Loan<br> Assistance',
    },
    {
      circleImage: BovineMark,
      heading: 'Bovine<br> Marketplace',
    },
    {
      circleImage: Insurance,
      heading: 'Insurance',
    },
  ];

  return (
    <div className="offering-wrapper">
      <div className="offering-heading">Our Offerings</div>

      <div className="offering-circles-section">
        <div className="offering-circles-section-Row">
          {circlesObject?.map((item) => (
            <OfferingsCircles props={item} />
          ))}
        </div>
      </div>
      {/* <button>Download Brochure</button> */}

      <a href={Brochure} download="Brochure" target="_blank" className="Brochure_link">
        <FormButton className="offering-button" title="Download Brochure" />
      </a>
      <div className="image-text">Be the Next Dairy Entrepreneur </div>
    </div>
  );
};

export default MDEOfferings;
