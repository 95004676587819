import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import QRCODE from '../../assets/images/QRCode.png';
import Mobile from '../../assets/images/newMobile.png';
import { ROUTES_PATH } from '../../utils/constants';
import './QRCode.css';

const QRCode = () => {
  const { ref, inView } = useInView({});
  const [url, setUrl] = useState();
  useEffect(() => {
    const location = window.location;
    setUrl(`${location.origin}${location.pathname}#${ROUTES_PATH.DGV_CONNECT}`);
  }, []);
  console.log(url);
  return (
    <div ref={ref} className="qr-container">
      <div className="qr-content">
        <div className={inView ? 'qr-text animate__animated animate__fadeInRight animate__delay-1s' : 'qr-text'}>
          <span className="me-1">SCAN TO DOWNLOAD</span>
          <span>THE APP</span>
        </div>
        <div className={inView ? 'machine-img animate__animated animate__fadeInLeft ' : 'machine-img'}>
          <img src={Mobile} alt="mobile"></img>
        </div>
        <div className={inView ? 'qr-code animate__animated animate__zoomIn animate__delay-3s' : 'qr-code'}>
          {/* <QRCodeLib
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={`${url}`}
            viewBox={`0 0 256 256`}
          /> */}
          <img className="qr-image" alt="qrCodeforDGVConnect" src={QRCODE}></img>
        </div>
      </div>
    </div>
  );
};

export default QRCode;
