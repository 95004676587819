import React from 'react';
import Carousel from '../Carousel/Carousel';
import ProductDescription from '../ProductDescription/ProductDescription';
import './ProductInfoPlot.css';

export default function ProductInfoPlot(props) {
  return (
    <div className="_productinfo_plot">
      <ProductDescription info={props.info} />
      <Carousel images={props.images} />
    </div>
  );
}
