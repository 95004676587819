import React from 'react';
import DropAMessage from '../../../components/DropAMessage';
import VisitUs from '../../../components/VisitUs/VisitUs';
import ContactOurTeam from '../../../components/ContactOurTeam/ContactOurTeam';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <>
      <div className="contactUs-main">
        <div className="contactUs-wrapper">
          <ContactOurTeam />
          <div className="Drop-a-message">
            <DropAMessage />
          </div>
        </div>
        <VisitUs />
      </div>
    </>
  );
};

export default ContactUs;
