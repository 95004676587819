import React from 'react';
import './FormTextArea.css';

export default function FormTextArea({ onChange, name, placeholder, label, value, rows, error, fClass }) {
  return (
    <div className="_form_input_row">
      <div className="label">{label}</div>
      <textarea
        className={fClass ? 'fClass' : '_text_box'}
        placeholder={placeholder}
        value={value}
        rows={rows}
        onChange={onChange}
        name={name}
      />
      {error}
    </div>
  );
}
