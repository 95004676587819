import React, { useState, useEffect } from 'react';
import img from '../../../src/assets/images/banner2.png';
import mobile_img from '../../../src/assets/images/mobile_hero_banner.png';
import DGV_pay from '../../../src/assets/images/dgv-pay.png';
import DGV_connect from '../../../src/assets/images/dgv-connect.png';
import DGV_money from '../../../src/assets/images/dgv-money.png';
import Mob_Dgv_pay from '../../assets/images/Mob_DGV_pay_new.svg';
import Mob_Dgv_connect from '../../assets/images/dgv-connect-m.png';
import Mob_Dgv_money from '../../assets/images/dgv-money-m.png';
import { ROUTES_PATH } from '../../utils/constants';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const HomePageSlide2 = ({ animated }) => {
  const [mobileView, setMobileView] = useState(false);
  const { ref } = useInView();

  const [defaultdata, setDefaultdata] = useState({
    banner_img: mobile_img,
    pay_img: Mob_Dgv_pay,
    money_img: Mob_Dgv_money,
    connect_img: Mob_Dgv_connect,
  });

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
      setDefaultdata({
        banner_img: img,
        pay_img: DGV_pay,
        money_img: DGV_money,
        connect_img: DGV_connect,
      });
    } else {
      setDefaultdata({
        banner_img: mobile_img,
        pay_img: Mob_Dgv_pay,
        money_img: Mob_Dgv_money,
        connect_img: Mob_Dgv_connect,
      });
    }
  }, [mobileView]);

  return (
    <div className="banner-slide" id="slide-2">
      <div className="home-banner-img">
        <img src={defaultdata.banner_img} alt="banner" />
      </div>
      <div className="banner-desc banner-desc-slide-2">
        <div
          ref={ref}
          className={
            animated === 1
              ? 'banner-title animate__animated animate__fadeInDown'
              : 'banner-title animate__animated animate__fadeOutLeft'
          }
        >
          Products
        </div>
        <div className="landing_product_wrapper">
          <div className="landing_product_block">
            <div
              className={
                animated === 1
                  ? '_landing_product_icon animate__animated animate__zoomIn animate__delay-1s'
                  : '_landing_product_icon animate__animated animate__fadeOutLeft '
              }
            >
              <img src={defaultdata.pay_img} alt="dgv-pay" />
            </div>
            <div
              className={
                animated === 1
                  ? '_landing_product_desc animate__animated animate__fadeInUp animate__delay-3s font-lato '
                  : '_landing_product_desc animate__animated animate__fadeOutLeft  font-lato'
              }
            >
              {mobileView ? (
                <>
                  Bringing door-step banking services for farmers and milk societies, Building financial infrastructure
                  for the dairy ecosystem
                </>
              ) : (
                <>
                  Bringing door-step banking services for
                  <br /> farmers and milk societies, Building
                  <br /> financial infrastructure for the dairy <br />
                  ecosystem
                </>
              )}
            </div>
            <Link
              to={ROUTES_PATH.PAY}
              className={
                animated === 1
                  ? 'know-more animate__animated animate__fadeInUp animate__delay-5s know-more-middle '
                  : 'know-more animate__animated animate__fadeOutLeft know-more-middle '
              }
            >
              know more
            </Link>
          </div>
          <div className="landing_product_block">
            <div
              className={
                animated === 1
                  ? '_landing_product_icon animate__animated animate__zoomIn animate__delay-6s width-50'
                  : '_landing_product_icon animate__animated animate__fadeOutLeft '
              }
            >
              <img src={defaultdata.money_img} alt="dgv-money" />
            </div>
            <div
              className={
                animated === 1
                  ? '_landing_product_desc animate__animated animate__fadeInUp animate__delay-8s  font-lato'
                  : '_landing_product_desc animate__animated animate__fadeOutLeft  font-lato'
              }
            >
              {mobileView ? (
                <>
                  Digital Bovine loan, Digital Working Capital, Digital KCC, etc to dairy farmers & dairy micro
                  enterprises in a simple, flexible & convenient manner
                </>
              ) : (
                <>
                  Digital Bovine loan, Digital Working Capital, Digital KCC, etc to dairy farmers & dairy micro
                  enterprises in a simple, flexible & convenient manner
                </>
              )}
            </div>
            <Link
              to={ROUTES_PATH.MONEY}
              className={
                animated === 1
                  ? 'know-more animate__animated animate__fadeInUp animate__delay-10s know-more-middle'
                  : 'know-more animate__animated animate__fadeOutLeft know-more-middle'
              }
            >
              know more
            </Link>
          </div>
          <div className="landing_product_block">
            <div
              className={
                animated === 1
                  ? '_landing_product_icon animate__animated animate__zoomIn animate__delay-11s'
                  : '_landing_product_icon animate__animated animate__fadeOutLeft '
              }
            >
              <img src={defaultdata.connect_img} alt="dgv-connect" />
            </div>
            <div
              className={
                animated === 1
                  ? '_landing_product_desc animate__animated animate__fadeInUp animate__delay-13s  font-lato'
                  : '_landing_product_desc animate__animated animate__fadeOutLeft  font-lato'
              }
            >
              {mobileView ? (
                <>
                  Integrated Bovine <br /> marketplace platform connecting buyers and sellers with embedded finance
                  options
                </>
              ) : (
                <>
                  Integrated Bovine <br /> marketplace platform connecting
                  <br /> buyers and sellers with embedded
                  <br /> finance options
                </>
              )}
            </div>
            <Link
              to={ROUTES_PATH.CONNECT}
              className={
                animated === 1
                  ? 'know-more animate__animated animate__fadeInUp animate__delay-15s know-more-middle'
                  : 'know-more animate__animated animate__fadeOutLeft know-more-middle'
              }
            >
              know more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSlide2;
