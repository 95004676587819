import React from 'react';
import './Overview.css';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const overview = [
  {
    img: 'backgr_01.png',
    heading: {
      start: 0,
      end: 8.5,
      delay: 1,
      duration: 2,
      decimals: 1,
    },
    sub_heading: 'Lakh Crore',
    desc: 'The Dairy sector is more than the combined value of wheat and rice production in 2022',
    icon: 'icon.png',
    delay: 0,
  },
  {
    img: 'backgr_02.png',
    heading: {
      start: 0,
      end: 210,
      delay: 4,
      duration: 2,
    },
    sub_heading: 'Billion',
    desc: '  Litres-India is the largest producer of dairy products in the world',
    icon: 'icon1.png',
    delay: 3,
  },
  {
    img: 'backgr_03.png',
    heading: {
      start: 0,
      end: 6.2,
      delay: 7,
      duration: 2,
      decimals: 1,
    },
    sub_heading: 'Percent',
    desc: 'The growth rate of milk production in India was over 6.2% in fiscal year 2022',
    icon: 'icon2.png',
    delay: 6,
  },
  {
    img: 'backgr_04.png',
    heading: {
      start: 0,
      end: 303,
      delay: 10,
      duration: 2,
    },
    sub_heading: 'Mn Bovines',
    desc: ' 193 million cattle and 110 million buffaloes, India has largest indigenous breed Bovines',
    icon: 'icon3.png',
    delay: 9,
  },
  {
    img: 'backgr_05.png',
    heading: {
      start: 0,
      end: 70,
      delay: 13,
      duration: 2,
    },
    sub_heading: 'Percent',
    desc: ' of the money that is received from the customers goes directly to the farmer',
    icon: 'icon4.png',
    delay: 12,
  },
  {
    img: 'backgr_06.png',
    heading: {
      start: 0,
      end: 5,
      delay: 16,
      duration: 2,
    },
    sub_heading: 'Percent',
    desc: " of India's total GDP and 25% of agricultural GDP is contributed by Dairy Industry",
    icon: 'icon5.png',
    delay: 15,
  },
];

const CardOverview = () => {
  const { ref, inView } = useInView({});

  return (
    <section className="dairy-overview-container">
      <div className="dairy-overview-title">Overview of Indian Dairy Industry</div>
      <div ref={ref} className="chart">
        {overview.map((item, i) => {
          const url = require(`../../assets/images/${item.img}`);
          return (
            <div
              key={i}
              className={
                inView
                  ? `dairy-overview-background-img animate__animated animate__fadeInUp animate__delay-${item.delay}s`
                  : 'dairy-overview-background-img'
              }
              style={{
                backgroundImage: `url(${url})`,
              }}
            >
              <div className="heading">{inView && <CountUp {...item.heading} />}</div>
              <div className="sub-heading">{item.sub_heading}</div>
              <div className="desc">{item.desc}</div>
              <div className="icon">
                <img src={require(`../../assets/images/${item.icon}`)} alt="milk_icon" />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CardOverview;
