import React, { useEffect, useState } from 'react';
import ContactUsImg from '../../assets/images/Contact_Us.png';
import ContactUsImgMob from '../../assets/images/Contact_us_mob.png';
import './ContactOurTeam.css';

const ContactOurTeam = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 991.98) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <>
      <div className="contactUs-banner">
        <div className="contactUs-contain">
          <div className="contactUs-contain-header">Contact Our Team</div>
          <div className="contactUs-contain-para">
            In case of inquiries, partnerships, media, business opportunities
          </div>
        </div>
        <div className="contactUs-img">
          <img src={mobileView ? ContactUsImg : ContactUsImgMob} alt="banner" />
        </div>
      </div>
    </>
  );
};

export default ContactOurTeam;
