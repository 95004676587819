import React from 'react';
import './FormButton.css';

export default function FormButton({ handleSubmit, title }) {
  return (
    <div className="_button" onClick={handleSubmit}>
      {title}
    </div>
  );
}
