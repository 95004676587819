export const ROUTES_PATH = {
  HOME: '/',
  CONNECT: '/connect',
  DGV_CONNECT: '/dgv-connect',
  PAY: '/pay',
  MONEY: '/money',
  NETWORK: '/our-network',
  CAREERS: '/careers',
  MEDIA_EVENTS: '/media-events',
  CONTACTUS: '/contact-us',
  COMPANY: '/company',
  ABOUT_US: '/about-us',
  SOLUTIONS: '/solution',
  LIFE_AT_DGV: '/life-at-dgv',
  MicroDairyEnterprise: '/Micro-Dairy-Enterprise',
};

export const MENU_ITEMS = [
  { title: 'DGV Group', linkTo: ROUTES_PATH.NETWORK, id: '1' },
  {
    title: 'Platforms',
    id: '2',
    subItems: [
      { title: 'DGV Pay', linkTo: ROUTES_PATH.PAY, id: '2.1' },
      { title: 'DGV Money', linkTo: ROUTES_PATH.MONEY, id: '2.2' },
      { title: 'DGV Connect', linkTo: ROUTES_PATH.CONNECT, id: '2.3' },
    ],
  },
  {
    title: 'Solutions',
    id: '3',
    linkTo: '',
    subItems: [
      { title: 'Partners', linkTo: ROUTES_PATH.SOLUTIONS + '#partners', id: '3.1' },
      { title: 'Dairy Unions', linkTo: ROUTES_PATH.SOLUTIONS + '#unions', id: '3.2' },
      { title: 'Dairy Societies', linkTo: ROUTES_PATH.SOLUTIONS + '#societies', id: '3.3' },
      { title: 'Dairy Farmers', linkTo: ROUTES_PATH.SOLUTIONS + '#farmers', id: '3.4' },
      { title: 'Micro Dairy Enterprise', linkTo: ROUTES_PATH?.MicroDairyEnterprise, id: '3.5' },
    ],
  },
  { title: ' Our Network', linkTo: ROUTES_PATH.NETWORK, id: '4' },
  {
    title: 'Company',
    id: '5',
    subItems: [
      { title: 'About Us', menuStyle: '', linkTo: ROUTES_PATH.ABOUT_US, id: '5.1' },
      {
        title: 'Board of Directors',
        // menuStyle: 'mobile-menu',
        linkTo: ROUTES_PATH.ABOUT_US + '#board-of-director',
        id: '5.5',
      },
      {
        title: 'Management Team',
        // menuStyle: 'mobile-menu',
        linkTo: ROUTES_PATH.ABOUT_US + '#management-team',
        id: '5.6',
      },

      { title: "CEO's Desk", linkTo: ROUTES_PATH.ABOUT_US + '#ceo-desk', id: '5.3' },
      {
        title: 'Vision & Mission',
        menuStyle: 'mobile-menu',
        linkTo: ROUTES_PATH.ABOUT_US + '#vision-mission',
        id: '5.4',
      },
      { title: 'Careers', linkTo: ROUTES_PATH.CAREERS, id: '5.2' },
      // { title: 'Milestones', menuStyle: 'mobile-menu', linkTo: '', id: '4.7' },
      // { title: 'Testimonials', menuStyle: 'mobile-menu', linkTo: '', id: '4.8' },
      { title: 'Contact Us', menuStyle: '', linkTo: ROUTES_PATH.CONTACTUS, id: '5.9' },
    ],
  },
  { title: 'Media & Events', linkTo: ROUTES_PATH.MEDIA_EVENTS, id: '6' },
];

export const PRODUCT_CONNECT_INFO = {
  productTitle: 'Integrated  Bovine <br/> discovery platform',
  productDescription:
    'DGV Connect is integrated marketplace platform for discovery of indigenous & cross bovine breeds which establishes a connect between buyer & seller with embedded finance options for facilitating digital bovine loans & insurance, a one-stop shop for dairy farmers.',

  videoTitle: [
    { id: '1', language: 'Hindi', link: 'https://www.youtube.com/embed/u_Jsoq1pZ5I' },
    { id: '2', language: 'Gujarati', link: 'https://www.youtube.com/embed/wrUTEASeE0w' },
  ],
  youTubeBtn: true,
};

export const PRODUCT_MONEY_INFO = {
  productTitle: 'Customised Digital<br/> Dairy Loans',
  productDescription:
    'Digital Bovine loan, Digital Working Capital, </br>Digital KCC, etc to dairy farmers & dairy micro </br>enterprises in a simple, flexible & convenient manner',
  // videoLink: 'https://www.youtube.com/embed/JPsySw3X9M8',
  videoTitle: [{ id: '1', language: 'English', link: 'https://www.youtube.com/embed/IZuudQHiU_w' }],
  youTubeBtn: true,
};

export const PRODUCT_PAY_INFO = {
  productTitle: 'Simplifying Payments for Dairy Ecosystem',
  productDescription:
    'Bringing door-step banking services for farmers and milk societies, Building financial infrastructure for the dairy ecosystem.	We are passionate about working for Rural & Semi-Urban Communities and want to transform the way Dairy Farmers & Milk Supply Chain Enterprises access BFSI.',
  videoLink: 'https://www.youtube.com/embed/JPsySw3X9M8',
  videoTitle: 'DGV Information',
};

export const CAREER_INFO = {
  productTitle: 'Be the Change!',
  productDescription:
    'Join us & digitalize the roadrails of Dairy Sector.We are a team of young, passionate people who celebrate working together and challenging status quo through innovative product proposition.',
};
export const ABOUT_US_INFO = {
  productTitle: 'Who we are',
  productDescription:
    'We aspires to be a one-stop platform for 80M Dairy farmers in India to become lifelong partners in their growth and prosperity. Be it access to financial services like formal banking with ease at the milk collection, society point, customized financial products for dairy farmers, productivity enhancement tools, integrated market place which will bring about ease and higher yield will be our singular aim.',
};

export const SellerBenefitData = [
  { id: 1, benefit: 'Standardized information about Bovines', logo: 'sellerIcon' },
  { id: 2, benefit: 'Involving the AI worker in establishing buyer/seller connect(Trust)', logo: 'sellerIcon' },
  { id: 3, benefit: 'Loan amount directly credited in your account', logo: 'sellerIcon' },
  { id: 6, benefit: 'Reduction of broker dependency', logo: 'sellerIcon' },
  { id: 7, benefit: 'Interested & Potential Buyers', logo: 'sellerIcon' },
];

export const BuyerBenefitData = [
  { id: 1, benefit: 'Wide variety of Indigenous breed', logo: 'BuyerIcon' },
  { id: 2, benefit: 'Save time, money on Bovine discovery', logo: 'BuyerIcon' },
  { id: 3, benefit: 'No-Commission to broker', logo: 'BuyerIcon' },
  { id: 4, benefit: 'Bovine Loan available on doorstep', logo: 'BuyerIcon' },
  { id: 5, benefit: 'Paravets & AI worker for assistance', logo: 'BuyerIcon' },
];
// benfitsPayData
export const BenefitsPayData = [
  {
    id: '1',
    contain: 'Integrated solution under one box which makes the banking process secure, reliable & Transparent',
    delay: '1s',
  },
  { id: '2', contain: 'Single Debit Multiple Credit facility available', delay: '2s' },
  { id: '3', contain: 'Door-step banking services provided to Dairy Societies and farmers', delay: '3s' },
  {
    id: '4',
    contain: 'Safety & security of cash by providing Cash Management Service (CMS) at milk societies',
    delay: '4s',
  },
  {
    id: '5',
    contain: 'Saving time, money & energy to avail banking service, leading to increase in productivity of farmers',
    delay: '5s',
  },
];

const PARENT_MENUS = {
  products: {
    name: 'Platforms',
    className: 'menu-text',
  },
  company: {
    name: 'Company',
    className: 'menu-text',
  },
  solutions: {
    name: 'Solutions',
    className: 'menu-text',
  },
};

export const ROUTES_BREADCRUMB = {
  connect: [
    PARENT_MENUS.products,
    {
      name: 'DGV Connect',
    },
  ],
  pay: [
    PARENT_MENUS.products,
    {
      name: 'DGV Pay',
    },
  ],
  money: [
    PARENT_MENUS.products,
    {
      name: 'DGV Money',
    },
  ],
  ournetwork: [
    {
      name: 'Our Network',
    },
  ],
  careers: [
    PARENT_MENUS.company,
    {
      name: 'Careers',
    },
  ],
  aboutus: [
    PARENT_MENUS.company,
    {
      name: 'About Us',
    },
  ],
  mediaevents: [
    {
      name: 'Media & Events',
    },
  ],
  contactus: [
    PARENT_MENUS.company,
    {
      name: 'Contact Us',
    },
  ],
  lifeatdgv: [
    PARENT_MENUS.company,
    {
      name: 'About-Us',
      linkTo: ROUTES_PATH.ABOUT_US,
      className: 'menu-text',
    },
    {
      name: 'Life At DGV',
    },
  ],
  solution: [
    {
      name: 'Solution',
    },
  ],
  MicroDairyEnterprise: [
    PARENT_MENUS.solutions,
    {
      name: 'Micro Dairy Enterprise',
    },
  ],
};

// footer data

export const FooterData = [
  {
    title: 'Platforms',
    id: '1',
    subItems: [
      { title: 'DGV Pay', linkTo: ROUTES_PATH.PAY, id: '1.1' },
      { title: 'DGV Money', linkTo: ROUTES_PATH.MONEY, id: '1.2' },
      { title: 'DGV Connect', linkTo: ROUTES_PATH.CONNECT, id: '1.3' },
    ],
  },
  {
    title: 'Solutions',
    id: '2',
    linkTo: ROUTES_PATH.SOLUTIONS,
    subItems: [
      { title: 'Partners', linkTo: ROUTES_PATH.SOLUTIONS + '#partners', id: '2.4' },
      { title: 'Dairy Unions', linkTo: ROUTES_PATH.SOLUTIONS + '#unions', id: '2.1' },
      { title: 'Dairy Societies', linkTo: ROUTES_PATH.SOLUTIONS + '#societies', id: '2.2' },
      { title: 'Dairy Farmers', linkTo: ROUTES_PATH.SOLUTIONS + '#farmers', id: '2.3' },
      { title: 'Micro Dairy Enterprise', linkTo: ROUTES_PATH.MicroDairyEnterprise, id: '2.5' },
    ],
  },

  {
    title: 'Company',
    id: '4',
    isExpand: false,
    subItems: [
      { title: 'About Us', linkTo: ROUTES_PATH.ABOUT_US, id: '4.1' },
      { title: 'Who we are', linkTo: ROUTES_PATH.ABOUT_US + '#who-we-are', id: '4.2' },
      { title: 'CEOs Desk', linkTo: ROUTES_PATH.ABOUT_US + '#ceo-desk', id: '4.3' },
      { title: 'Vision & Mission', linkTo: ROUTES_PATH.ABOUT_US + '#vision-mission', id: '4.4' },
      { title: 'Board of Directors', linkTo: ROUTES_PATH.ABOUT_US + '#board-of-director', id: '4.5' },
      { title: 'Management Team', linkTo: ROUTES_PATH.ABOUT_US + '#management-team', id: '4.6' },
      { title: 'Life at DGV', linkTo: ROUTES_PATH.ABOUT_US + '#life-at-dgv', id: '4.7' },
      { title: 'Contact Us', linkTo: ROUTES_PATH.CONTACTUS, id: '4.8' },
    ],
  },

  {
    title: ' Our Network',
    id: '3',
    linkTo: ROUTES_PATH.NETWORK,
    isExpand: false,
    subItems: [{ title: 'Media & Events', linkTo: ROUTES_PATH.MEDIA_EVENTS, id: '5.1', class: 'bold' }],
  },

  {
    title: 'Quick Links',
    id: '6',
    isExpand: false,
    subItems: [
      {
        id: '6.1',
        title: 'Privacy Policy',
      },
      {
        id: '6.2',
        title: 'Privacy Policy Lending',
      },
      {
        id: '6.3',
        title: 'Terms & Conditions',
      },
      { title: 'Careers', linkTo: ROUTES_PATH.CAREERS, id: '6.4' },
    ],
  },
];

export const CONNECT_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../assets/images/Connect1.png') },
  { id: 2, name: '', path: require('../assets/images/Connect2.png') },
  { id: 3, name: '', path: require('../assets/images/Connect3.png') },
  { id: 4, name: '', path: require('../assets/images/Connect4.png') },
];

export const PAY_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../assets/images/Pay1.png') },
  { id: 2, name: '', path: require('../assets/images/Pay2.png') },
  { id: 3, name: '', path: require('../assets/images/Pay3.png') },
  { id: 4, name: '', path: require('../assets/images/Pay4.png') },
];

export const MONEY_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../assets/images/Money1.png') },
  { id: 2, name: '', path: require('../assets/images/Money2.png') },
  { id: 3, name: '', path: require('../assets/images/Money3.png') },
  { id: 4, name: '', path: require('../assets/images/Money4.png') },
];
export const NETWORK_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../assets/images/network1.png') },
  { id: 2, name: '', path: require('../assets/images/network2.png') },
  { id: 3, name: '', path: require('../assets/images/network3.png') },
  { id: 4, name: '', path: require('../assets/images/network4.png') },
  { id: 5, name: '', path: require('../assets/images/network5.png') },
];
export const LoanTypesData = {
  item1: {
    id: 1,
    icon: 'capital_loan.svg',
    icontitle: 'capital-loan-icon',
    loantitle: 'Working Capital loans for Milk Societies',
    loandesc:
      'Working Capital loan is a facility that is taken to fulfill day to day operational need or meet short term cash requirement of an organization. This is overdraft facility, allows Organization to withdraw money from the account despite having no balance. There is limit on the amount that can be overdrawn from the account. Overdraft facility is allowed for up to One year.',
    loanimg: 'capital_loan_img.png',
    imgWidthClass: 'firstImg',
    loanimgtitle: 'capital-loan-img',
    benefitslist: [
      {
        id: 1,
        list: 'Suitable for short-term borrowing needs of an organization.',
      },
      {
        id: 2,
        list: 'No Charges for clearing the overdraft earlier than expected.',
      },
      {
        id: 3,
        list: 'Interest is paid only on the overdrawn balance.',
      },
      {
        id: 4,
        list: 'Easy & Quick: - With immediate access to funds once the facility has been agreed.',
      },
      {
        id: 5,
        list: 'Facility amount can be tailored to suit business needs, including both cashflow and back-up against unexpected expenditures.',
      },
    ],
  },
  item2: {
    id: 2,
    icon: 'cattle_loan.svg',
    icontitle: 'cattle_loan-icon',
    loantitle: 'Digital Bovine Loan',
    loandesc:
      'Bovine digital loan is a financial product designed specifically for dairy farmers to help them access credit to purchase Bovine (Cow/Buffalo). It is a loan that can be applied completely digitally through DGV, making it convenient for farmers to secure the financing they need without having to visit a bank or other financial institution in person making it convenient and easy for farmers to apply and receive funding in a quick and effective manner.',
    loanimg: 'cattle_loan_img.png',
    imgWidthClass: 'restImg',
    loanimgtitle: 'cattle_loan_img-img',
    benefitslist: [
      {
        id: 1,
        list: 'Avail loans in quicker, simpler, paperless mode.',
      },
      {
        id: 2,
        list: 'Door-step facility, Farmers don’t need to visit bank for their cattle loans',
      },
      {
        id: 3,
        list: 'Digital cattle insurance is also provided with loans',
      },
      {
        id: 4,
        list: 'You can get loan amounts of as little as ₹60,000 upto ₹3 lakhs with easy repayment.',
      },
      {
        id: 5,
        list: 'Not only loans to buy cattle, but an integrated platform to find desired cattle through DGV CONNECT.',
      },
      {
        id: 6,
        list: 'Simple-repayment via milk societies',
      },
    ],
  },
  item3: {
    id: 3,
    icon: 'term_icon.svg',
    icontitle: 'term_icon-icon',
    loantitle: 'Digital Term Loan',
    loandesc:
      'A digital term loan for dairy farmers offered by DGV is a financial product that allows dairy farmers to borrow a set amount of money for a period upto 3 years. The loan is offered digitally, which means that the application process and loan management can be done online, making it convenient and efficient for farmers to access the funding they need. Dairy farmers can use a digital term loan to finance a variety of needs, such as purchasing feed, upgrading equipment or expanding their operations. ',
    loanimg: 'term_loan_img.png',
    imgWidthClass: 'restImg',
    loanimgtitle: 'cattle_loan_img-img',

    benefitslist: [
      {
        id: 1,
        list: 'Simple, streamlined application process of DGV Term loan makes the loan application process journey easy.',
      },
      {
        id: 2,
        list: 'Lower interest rate for the loan makes DGV Term loan best fit for small, medium need of the farmer.',
      },
      {
        id: 3,
        list: 'Interest is paid only on the overdrawn balance.',
      },
      {
        id: 4,
        list: 'Freedom to use the cash based on personal requirement: There is no restriction on the usage of the cash received from the loan.',
      },
      {
        id: 5,
        list: 'Fast approval of loan.',
      },
      {
        id: 6,
        list: 'DGV term loan timely repayment helps increase the credit history and thereby qualifying him/her for larger loan cap.',
      },
    ],
  },
  item4: {
    id: 4,
    icon: 'kcc_to_dairy.svg',
    icontitle: 'kcc_to_dairy-icon',
    loantitle: 'KCC to Dairy Farmer',
    loandesc:
      'DGV facilitates digital dairy KCC for the dairy farmers. It eliminates the current manual process of collecting the document, verfiying it and processing the Dairy KCC and replaces it through its AMC’s integration and complete digital process to provide Dairy KCC in a much quicker and effecient way. Farmer may use this to purchase of cattle feed and nutrition, cattle healthcare & Insurance of cattle etc.',
    loanimg: 'kcc_to_dairy_img.png',
    imgWidthClass: 'lastImg',
    loanimgtitle: 'kcc_to_dairy_img-img',
    benefitslist: [
      {
        id: 1,
        list: 'Avail loans in quicker, efficient & simpler process',
      },
      {
        id: 2,
        list: 'Complete digital process, paperless mode',
      },
      {
        id: 3,
        list: 'Quick disbursement of loans directly in to the account.',
      },
      {
        id: 4,
        list: 'Reduced operational cost',
      },
      {
        id: 5,
        list: 'No Manual room for error because of automated process',
      },
    ],
    // mobileBenefitsList: [
    //   {
    //     id: 1,
    //     list: 'Complete paperless process',
    //   },
    //   {
    //     id: 2,
    //     list: 'No manual room for error',
    //   },
    //   {
    //     id: 3,
    //     list: 'Quicker disbursement.',
    //   },
    //   {
    //     id: 4,
    //     list: 'Reduced operational cost',
    //   },
    // ],
  },
};

// //////////////////////////////////career page //////////////////////////////////

export const CAREER_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../assets/images/Careers/career-w1.png') },
  { id: 2, name: '', path: require('../assets/images/Careers/career-w2.png') },
  { id: 3, name: '', path: require('../assets/images/Careers/career-w3.png') },
  { id: 4, name: '', path: require('../assets/images/Careers/career-w4.png') },
];

/////////////////////////////////// contact-us ////////////////////////////////////
export const DropMessageFormInputTypes = [
  {
    id: 1,
    label: '',
    name: 'name',
    type: 'text',
    placeholder: 'Your Full Name*',
  },
  {
    id: 2,
    label: '',
    type: 'email',
    name: 'email',
    placeholder: 'Email ID*',
  },
  {
    id: 3,
    label: '',
    name: 'subject',
    type: 'text',
    placeholder: 'Subject',
  },
];

export const OfficeAddress = [
  {
    id: '1',
    officeType: 'Corporate Office',
    address: 'J-3030,  Akshar Business Park, Plot No-03, Sector 25, Vashi, Navi Mumbai, Maharashtra 400703',
    locationIcon: require('../assets/images/Location-Icon.png'),
    viewOnMap: 'View On Map',
    className: 'corporate_office',

    mapLink:
      'https://www.google.com/maps/search/3rd+Floor,+J+wing,+Plot+No.+3,+Akshar+Business+Park,+Sector+25,+Vashi,+Navi+Mumbai,+Maharashtra-400703/@19.0790213,73.0109658,17z/data=!3m1!4b1',
  },
  {
    id: '2',
    officeType: 'Registered Office',
    address: 'No.562, 19th Cross, 1st Block, R.T.Nagar, Bengaluru, Karnataka 560032',
    locationIcon: require('../assets/images/Location_Icon.png'),
    viewOnMap: 'View On Map',
    className: 'register_office',
    mapLink:
      'https://www.google.com/maps/place/562,+19th+Cross+Rd,+Thimaiah+Garden,+Devara+Jeevanahalli,+Bengaluru,+Karnataka+560032/@13.0168847,77.5937682,16z/data=!4m5!3m4!1s0x3bae17ad92a50887:0x174957aa9d7e533a!8m2!3d13.0167241!4d77.5970537',
  },
  {
    id: '3',
    officeType: 'State Head Office',
    className: 'stateHead_office',

    address:
      'Vasudev Building, Office No 305/306, 3rd Floor, Near Sardar Patel Statue, Vallabh Vidya Nagar, Gujarat- 388120',
    locationIcon: require('../assets/images/Location_Icon.png'),
    viewOnMap: 'View On Map',
    mapLink: 'https://maps.app.goo.gl/TGVQWqidsqcDpX6B7',
  },
];

export const ContactUsEmails = {
  id: 1,
  principalNobalOfficer: 'pno@dgv.in',
  joinUs: 'careers@dgv.in',
};

export const ContactUsNumber = {
  callCenterOne: 'hello@dgv.in',
};

/*****************************CAREER PAGE DATA******************************************/
export const CAREER_PERKS_LOGOS = [
  {
    id: 'row1',
    children: [
      { id: 1, logoImg: 'perkLogo1.svg', perkTitle: 'Competitive Salary' },
      { id: 2, logoImg: 'perkLogo2.svg', perkTitle: 'Everyday Learning' },
      { id: 3, logoImg: 'perkLogo3.svg', perkTitle: 'Fast-track Progression' },
      { id: 4, logoImg: 'perkLogo4.svg', perkTitle: 'Relocation Assistance' },
    ],
    perkClass: 'perk-row1',
  },
  {
    id: 'row2',
    children: [
      { id: 1, logoImg: 'perkLogo5.svg', perkTitle: 'Amazing Team Bonding' },
      { id: 2, logoImg: 'perkLogo6.svg', perkTitle: 'Generous Public Holidays' },
      { id: 3, logoImg: 'perkLogo7.svg', perkTitle: 'Maternity & Paternity Leave' },
    ],
    perkClass: 'perk-row2',
  },
  {
    id: 'row3',
    children: [
      { id: 1, logoImg: 'perkLogo8.svg', perkTitle: 'Flexible Work hours' },
      { id: 2, logoImg: 'perkLogo9.svg', perkTitle: 'Employee Mediclaim & Accident coverage' },
    ],
    perkClass: 'perk-row3',
  },
];

export const Mobile_perks_logos = [
  { id: 1, logoImg: 'perkLogo1.svg', perkTitle: 'Competitive Salary' },
  { id: 2, logoImg: 'perkLogo2.svg', perkTitle: 'Everyday Learning' },
  { id: 3, logoImg: 'perkLogo3.svg', perkTitle: 'Fast-track Progression' },
  { id: 4, logoImg: 'perkLogo4.svg', perkTitle: 'Relocation Assistance' },
  { id: 5, logoImg: 'perkLogo5.svg', perkTitle: 'Amazing Team Bonding' },
  { id: 6, logoImg: 'perkLogo6.svg', perkTitle: 'Generous Public Holidays' },
  { id: 7, logoImg: 'perkLogo7.svg', perkTitle: 'Maternity & Paternity Leave' },
  { id: 8, logoImg: 'perkLogo8.svg', perkTitle: 'Flexible Work hours' },
  { id: 9, logoImg: 'perkLogo9.svg', perkTitle: 'Employee Mediclaim & Accident coverage' },
];

/*************************************employee Testimonial data****************************** */

export const EmployeeTestimonialArr = [
  {
    id: 1,
    imgPath: require('../assets/images/Careers/employee3.png'),
    name: 'MANTHAN KISHOR RAUT',
    designation: 'Manager - Product',
    experience:
      'I am really grateful to work at DGV. I find it to be a place where I can grow together with the organization. I joined DGV in product vertical where I get to learn something new everyday. With the constant support you have from the Top Management which consists of leading experts from the Banking ecosystem, I would say DGV is a place to work where we can accomplish more together',
  },
  {
    id: 2,
    imgPath: require('../assets/images/Careers/employee1.png'),
    name: 'AVINASH RANA',
    designation: 'Software Engineer',
    experience:
      'I am thrilled to join DGV and be a part of its exciting growth journey. I’ve handled a variety of assignments and learned so many new things in my short span with DGV. The company’s environment is fresh and boosts transparency and maturity in the culture.',
  },
  {
    id: 3,
    imgPath: require('../assets/images/Careers/employee2.png'),
    name: 'DIPENDRA JADHAV',
    designation: 'Manager – Finance',
    experience:
      'It has been an amazing journey with DGV so far. I have had the opportunity to learn and grow. The company environment has given me a safe space to learn through challenges while working on various DGV products. Excited to witness the journey unfold!',
  },

  {
    id: 4,
    imgPath: require('../assets/images/Careers/employee4.png'),
    name: 'AVDHOOT MALGAONKAR',
    designation: 'Assistant Manager – IT Infra and Infosec',
    experience:
      'DGV always has a high energy atmosphere, transparent culture & amazing leadership support with loads of learning opportunities. It has helped me act as an entrepreneur in my own space. Being part of DGV the first hand experience of setting up the various process has been great learning.',
  },
];

export const OUR_NETWORK = {
  productTitle: 'Our People, Our Place, <br/>Our Network',
  productDescription:
    'Building BFSI & Marketplace access as a trustworthy and responsible partner for Dairy ecosystem, Dairy Micro-enterprises & Dairy Farmers',
  videoLink: 'https://www.youtube.com/embed/JPsySw3X9M8',
  videoTitle: 'DGV Information',
};

export const STRATIGIC_PARTNER = [
  { id: 1, name: '', path: require('../assets/images/Strategic-partner-icon1.png') },
  { id: 2, name: '', path: require('../assets/images/Strategic-partner-icon2.png') },
  { id: 3, name: '', path: require('../assets/images/Strategic-partner-icon3.png') },
  { id: 4, name: '', path: require('../assets/images/Strategic-partner-icon4.png') },
  { id: 5, name: '', path: require('../assets/images/Strategic-partner-icon5.png') },
  { id: 6, name: '', path: require('../assets/images/Strategic-partner-icon6.png') },
  { id: 7, name: '', path: require('../assets/images/Strategic-partner-icon7.png') },
  { id: 8, name: '', path: require('../assets/images/Strategic-partner-icon8.png') },
  { id: 9, name: '', path: require('../assets/images/Strategic-partner-icon9.png') },
  { id: 10, name: '', path: require('../assets/images/Strategic-partner-icon10.png') },
  { id: 11, name: '', path: require('../assets/images/Strategic-partner-icon11.png') },
  { id: 12, name: '', path: require('../assets/images/Strategic-partner-icon12.png') },
  { id: 13, name: '', path: require('../assets/images/Strategic-partner-icon13.png') },
  { id: 14, name: '', path: require('../assets/images/Strategic-partner-icon14.png') },
];

//********************************ABOUT US *******************************************/

export const WHO_WE_ARE = {
  productTitle: 'Who we are',
  productDescription:
    'We aspire to be an one-stop platform for 80M Dairy farmers in India to become lifelong partners in their growth and prosperity. DGV PAY simplifies the payments for the dairy value chain. DGV MONEY provide access to digital dairy loans for milk societies & dairy farmers. DGV CONNECT is an integrated bovine marketplace platform connecting buyers and sellers with embedded digital finance options.',
  videoLink: 'https://www.youtube.com/embed/JPsySw3X9M8',
  videoTitle: 'DGV Information',
};

export const ABOUT_US_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../pages/Company/AboutUs/images/aboutus-web1.png') },
  { id: 2, name: '', path: require('../pages/Company/AboutUs/images/about-us-slide-2.png') },
  { id: 3, name: '', path: require('../pages/Company/AboutUs/images/aboutus-web3.png') },
  { id: 4, name: '', path: require('../pages/Company/AboutUs/images/aboutus-web2.png') },
];

// Life-At-DGV-Inside

export const Life_At_DGV_Data = [
  {
    title: 'DGV Trek to Songadh',
    id: 'DgvTrek',
    images: [
      { img: 'DgvTrek-w1.png', mobImg: 'DgvTrek-m1.png' },
      {
        img: 'DgvTrek-w2.png',
        mobImg: 'DgvTrek-m2.png',
      },
      {
        img: 'DgvTrek-w3.png',
        mobImg: 'DgvTrek-m3.png',
      },

      {
        img: 'DgvTrek-w4.png',
        mobImg: 'DgvTrek-m4.png',
      },
      {
        img: 'DgvTrek-w5.png',
        mobImg: 'DgvTrek-m5.png',
      },
      {
        img: 'DgvTrek-w6.png',
        mobImg: 'DgvTrek-m6.png',
      },
      {
        img: 'DgvTrek-w7.png',
        mobImg: 'DgvTrek-m7.png',
      },
      {
        img: 'DgvTrek-w8.png',
        mobImg: 'DgvTrek-m8.png',
      },
      {
        img: 'DgvTrek-w9.png',
        mobImg: 'DgvTrek-m9.png',
      },
      {
        img: 'DgvTrek-w10.png',
        mobImg: 'DgvTrek-m10.png',
      },
      {
        img: 'DgvTrek-w11.png',
        mobImg: 'DgvTrek-m11.png',
      },
    ],
  },
  {
    title: '3rd Anniversary Celebration',
    id: '3rdAnniversary',
    images: [
      { img: '3rdAnniversary-w1.png', mobImg: '3rdAnniversary-m1.png' },
      {
        img: '3rdAnniversary-w2.png',
        mobImg: '3rdAnniversary-m2.png',
      },
      {
        img: '3rdAnniversary-w3.png',
        mobImg: '3rdAnniversary-m3.png',
      },

      {
        img: '3rdAnniversary-w4.png',
        mobImg: '3rdAnniversary-m4.png',
      },
    ],
  },
  {
    title: 'Full Stack Training @LetsUpgrade',
    id: 'DgvTraining',
    images: [
      { img: 'training-w1.png', mobImg: 'training-m1.png' },
      {
        img: 'training-w2.png',
        mobImg: 'training-m2.png',
      },
      {
        img: 'training-w3.png',
        mobImg: 'training-m3.png',
      },

      {
        img: 'training-w4.png',
        mobImg: 'training-m4.png',
      },
    ],
  },
  {
    title: 'DGV offsite & team building activities at Della',
    id: 'offsite',
    images: [
      { img: 'della-w1.png', mobImg: 'della-m1.png' },
      {
        img: 'della-w2.png',
        mobImg: 'della-m2.png',
      },
      {
        img: 'della-w3.png',
        mobImg: 'della-m3.png',
      },
      {
        img: 'della-w4.png',
        mobImg: 'della-m4.png',
      },
      {
        img: 'della-w5.png',
        mobImg: 'della-m5.png',
      },
      {
        img: 'della-w6.png',
        mobImg: 'della-m6.png',
      },
      {
        img: 'della-w7.png',
        mobImg: 'della-m7.png',
      },
      {
        img: 'della-w8.png',
        mobImg: 'della-m8.png',
      },
      {
        img: 'della-w9.png',
        mobImg: 'della-m9.png',
      },
    ],
  },
  {
    title: 'DGV Annual Sales Meet 2022-2023 Place: Chotila - Gujarat',
    id: 'annual',
    images: [
      { img: 'gujarat-w1.png', mobImg: 'gujarat-m1.png' },
      {
        img: 'gujarat-w2.png',
        mobImg: 'gujarat-m2.png',
      },
      {
        img: 'gujarat-w3.png',
        mobImg: 'gujarat-m3.png',
      },
      {
        img: 'gujarat-w4.png',
        mobImg: 'gujarat-m4.png',
      },
      {
        img: 'gujarat-w5.png',
        mobImg: 'gujarat-m5.png',
      },
      {
        img: 'gujarat-w6.png',
        mobImg: 'gujarat-m6.png',
      },
      {
        img: 'gujarat-w7.png',
        mobImg: 'gujarat-m7.png',
      },
      {
        img: 'gujarat-w8.png',
        mobImg: 'gujarat-m8.png',
      },
      {
        img: 'gujarat-w9.png',
        mobImg: 'gujarat-m9.png',
      },
    ],
  },
  {
    title: 'Holi @ DGV Office',
    id: 'holi',
    images: [
      { img: 'holi-w1.png', mobImg: 'holi-m1.png' },
      {
        img: 'holi-w2.png',
        mobImg: 'holi-m2.png',
      },
      {
        img: 'holi-w3.png',
        mobImg: 'holi-m3.png',
      },
      {
        img: 'holi-w4.png',
        mobImg: 'holi-m4.png',
      },
      {
        img: 'holi-w5.png',
        mobImg: 'holi-m5.png',
      },
      {
        img: 'holi-w6.png',
        mobImg: 'holi-m6.png',
      },
      {
        img: 'holi-w7.png',
        mobImg: 'holi-m7.png',
      },
      {
        img: 'holi-w8.png',
        mobImg: 'holi-m8.png',
      },
      {
        img: 'holi-w9.png',
        mobImg: 'holi-m9.png',
      },
    ],
  },
  {
    title: "Women's Day Celebration",
    id: 'womenday',
    images: [
      { img: 'woman-w1.png', mobImg: 'woman-m1.png' },
      {
        img: 'woman-w2.png',
        mobImg: 'woman-m2.png',
      },
      {
        img: 'woman-w3.png',
        mobImg: 'woman-m3.png',
      },
      {
        img: 'woman-w4.png',
        mobImg: 'woman-m4.png',
      },
      {
        img: 'woman-w5.png',
        mobImg: 'woman-m5.png',
      },
      {
        img: 'woman-w6.png',
        mobImg: 'woman-m6.png',
      },
      {
        img: 'woman-w7.png',
        mobImg: 'woman-m7.png',
      },
      {
        img: 'woman-w8.png',
        mobImg: 'woman-m8.png',
      },
      {
        img: 'woman-w9.png',
        mobImg: 'woman-m9.png',
      },
    ],
  },
  {
    title: 'Corporate Office Inauguration Navi Mumbai',
    id: 'corporate',
    images: [
      { img: 'mum-w1.png', mobImg: 'mumbai-m1.png' },
      {
        img: 'mum-w2.png',
        mobImg: 'mumbai-m2.png',
      },
      {
        img: 'mum-w3.png',
        mobImg: 'mumbai-m3.png',
      },
      {
        img: 'mum-w4.png',
        mobImg: 'mumbai-m4.png',
      },
      {
        img: 'mum-w5.png',
        mobImg: 'mumbai-m5.png',
      },
      {
        img: 'mum-w6.png',
        mobImg: 'mumbai-m6.png',
      },
      {
        img: 'mum-w7.png',
        mobImg: 'mumbai-m7.png',
      },
      {
        img: 'mum-w8.png',
        mobImg: 'mumbai-m8.png',
      },
      {
        img: 'mum-w9.png',
        mobImg: 'mumbai-m9.png',
      },
      {
        img: 'mum-w10.png',
        mobImg: 'mumbai-m10.png',
      },
      {
        img: 'mum-w11.png',
        mobImg: 'mumbai-m11.png',
      },
      {
        img: 'mum-w12.png',
        mobImg: 'mumbai-m12.png',
      },
      {
        img: 'mum-w13.png',
        mobImg: 'mumbai-m13.png',
      },
      {
        img: 'mum-w14.png',
        mobImg: 'mumbai-m14.png',
      },
      {
        img: 'mum-w15.png',
        mobImg: 'mumbai-m15.png',
      },
      {
        img: 'mum-w16.png',
        mobImg: 'mumbai-m16.png',
      },
      {
        img: 'mum-w17.png',
        mobImg: 'mumbai-m17.png',
      },
      {
        img: 'mum-w18.png',
        mobImg: 'mumbai-m18.png',
      },
    ],
  },
  {
    title: '1st Anniversary celebration with the field staff',
    id: 'anniversary',
    images: [
      { img: 'anni-w1.png', mobImg: 'anni-m1.png' },
      {
        img: 'anni-w2.png',
        mobImg: 'anni-m2.png',
      },
      {
        img: 'anni-w3.png',
        mobImg: 'anni-m3.png',
      },
      {
        img: 'anni-w4.png',
        mobImg: 'anni-m4.png',
      },
    ],
  },
];

////////////////////////////// media and event page //////////////////////////////
export const MEDIA_EVENT_INFO = {
  productTitle: 'DGV in News',
  productDescription:
    'Read the latest announcements, statements and press releases from Digivriddhi Technologies Private Limited, as well as speeches and interviews by the members.',
};
export const media = [
  {
    img: 'event-web13.png',
    desc: 'Omidyar Network India leads INR 50 crores ($6 million) Series A funding round in dairy fintech platform DGV',
    sub_heading: 'Watch Now',
    icon: 'watch_now_icon.png',
    link: 'https://www.youtube.com/embed/IDY6C3a8HJs?si=FiGBsbAZbj5UMGqe',
  },
  {
    img: 'event-web12.png',
    desc: 'Improving financial health of dairy farmers and micro-enterprises: Why we invested in DGV',
    sub_heading: 'Read Now',
    link: 'https://www.omidyarnetwork.in/blog/improving-financial-health-of-dairy-farmers-and-micro-enterprises-why-we-invested-in-dgv',
  },
  {
    img: 'event-web11.png',
    desc: 'Dairy fintech startup Digivriddhi raises $6 million in funding led by Omidyar Network India',
    sub_heading: 'Read Now',
    link: 'https://economictimes.indiatimes.com/tech/funding/dairy-fintech-startup-digivriddhi-raises-6-million-in-funding-led-by-omidyar-network/articleshow/105746831.cms',
  },
  {
    img: 'event-web10.png',
    desc: 'Omidyar Network India Leads Rs 50 Crore Series A Funding for Dairy Fintech Platform DGV',
    sub_heading: 'Read Now',
    link: 'https://startupstorymedia.com/insights-omidyar-network-india-leads-rs-50-crore-series-a-funding-for-dairy-fintech-platform-dgv/',
  },
  {
    img: 'event-web9.png',
    desc: 'Digivriddhi Technologies to utilise $6 million Series A fundraise for geographical expansion',
    sub_heading: 'Read Now',
    link: 'https://www.cnbctv18.com/videos/startup/startup-funding-digivriddhi-technologies-dairy-fintech-18498701.htm',
  },
  {
    img: 'event-web0.png',
    desc: 'Enabling Digital Dairy Loans with milk suppliers AMCS data',
    sub_heading: 'Read Now',
    link: 'https://m.timesofindia.com/business/india-business/rbi-uses-milk-supply-data-for-dairy-farmer-loans/amp_articleshow/101841261.cms',
  },
  {
    img: 'event-web1.png',
    desc: 'DGV to provide dairy farmers access to institutional credit',
    sub_heading: 'Read Now',
    link: 'https://www.thehindubusinessline.com/economy/agri-business/dairy-fintech-start-up-dgv-to-provide-farmers-access-to-institutional-credit/article66057863.ece/amp/',
  },
  {
    img: 'event-web2.png',
    desc: "DGV's service in Gujarat at Rajkot Dairy Union Anandpur",
    sub_heading: 'Watch Now',
    icon: 'watch_now_icon.png',
    link: 'https://www.youtube.com/embed/auN8NwS6xeo?si=feVonhQOl44fRC4c',
  },
  {
    img: 'event-web3.png',
    desc: 'Dairy-focused neobank DGV gets $3.1 million in first institutional funding',
    sub_heading: 'Read Now',
    link: 'https://economictimes.indiatimes.com/tech/funding/dairy-focused-neobank-dgv-gets-3-1-million-in-first-institutional-funding/articleshow/87730311.cms?from=mdr',
  },
  {
    img: 'event-web4.png',
    desc: "Rajkot's dairy brings cash to the doorstep of milk producers",
    sub_heading: 'Read Now',
    link: 'https://www.thehindubusinessline.com/economy/agri-business/rajkot-dairy-brings-cash-to-the-doorstep-of-milk-producers/article34771231.ece',
  },
  {
    img: 'event-web5.png',
    desc: 'Innovation in Dairy Sector- India International Dairy Expo 2022 – Day 2',
    sub_heading: 'Read Now',
    link: 'https://www.foodtechbiz.com/iide-2022/india-international-dairy-expo-iide-indias-most-comprehensive-dairy-and-dairy-technology-trade-fair-concludes-successfully-from-13-15-april-2022',
  },
  {
    img: 'event-web6.png',
    desc: 'IIDE 2022: Meet Digivriddhi Technologies, a Dairy-focused Neo-Banking',
    sub_heading: 'Read Now',
    link: 'https://www.foodtechbiz.com/iide-2022/meet-digivriddhi-technologies-a-dairy-focused-neo-banking-startup-at-iide',
  },
  {
    img: 'event-web7.png',
    desc: 'Amul Launches Micro ATM To Make Digital Payments To Dairy Farmers',
    sub_heading: 'Read Now',
    link: 'https://ruralmarketing.in/stories/amul-launches-micro-atm-to-make-digital-payments-to-dairy-farmers/',
  },
  {
    img: 'event-web8.png',
    desc: 'DGV raises $3.1M from Info Edge Ventures, Omnivore',
    sub_heading: 'Read Now',
    link: 'https://www.thehindubusinessline.com/companies/dgv-raises-31-m-from-info-edge-ventures-omnivore/article37514992.ece',
  },
];

export const events = [
  {
    id: '1',
    img: 'event_img1.png',
  },
  {
    id: '2',
    img: 'event_img2.png',
  },
  {
    id: '3',
    img: 'event_img3.png',
  },
  {
    id: '4',
    img: 'event_img2.png',
  },
];

export const EventData = [
  {
    title: 'Participated at GFF 2023 to showcase DGV Product Suite',
    titleMob: 'Participated at GFF 2023 to showcase DGV Product Suite',
    images: [
      {
        img: 'gff-web1.png',
        imgMob: 'gff-mob1.png',
      },
      {
        img: 'gff-web2.png',
        imgMob: 'gff-mob2.png',
      },
      {
        img: 'gff-web3.png',
        imgMob: 'gff-mob3.png',
      },
      {
        img: 'gff-web4.png',
        imgMob: 'gff-mob4.png',
      },
      {
        img: 'gff-web5.png',
        imgMob: 'gff-mob5.png',
      },
      {
        img: 'gff-web6.png',
        imgMob: 'gff-mob6.png',
      },
      {
        img: 'gff-web7.png',
        imgMob: 'gff-mob7.png',
      },
      {
        img: 'gff-web8.png',
        imgMob: 'gff-mob8.png',
      },
      {
        img: 'gff-web9.png',
        imgMob: 'gff-mob9.png',
      },
      {
        img: 'gff-web10.png',
        imgMob: 'gff-mob10.png',
      },
      {
        img: 'gff-web11.png',
        imgMob: 'gff-mob11.png',
      },
      {
        img: 'gff-web12.png',
        imgMob: 'gff-mob12.png',
      },
      {
        img: 'gff-web13.png',
        imgMob: 'gff-mob13.png',
      },
      {
        img: 'gff-web14.png',
        imgMob: 'gff-mob14.png',
      },
      {
        img: 'gff-web15.png',
        imgMob: 'gff-mob15.png',
      },
      {
        img: 'gff-web16.png',
        imgMob: 'gff-mob16.png',
      },
      {
        img: 'gff-web17.png',
        imgMob: 'gff-mob17.png',
      },
    ],
  },
  {
    title: 'Showcasing Digital Bovine Loan Journey with our partner Banks to entire Board of RBI',
    titleMob: 'Showcasing Digital Bovine Loan Journey with our partner Banks to entire Board of RBI',
    images: [
      {
        img: 'rbi-web1.png',
        imgMob: 'rbi-mob1.png',
      },
      {
        img: 'rbi-web2.png',
        imgMob: 'rbi-mob2.png',
      },
      {
        img: 'rbi-web3.png',
        imgMob: 'rbi-mob3.png',
      },
      {
        img: 'rbi-web4.png',
        imgMob: 'rbi-mob4.png',
      },
    ],
  },
  {
    title: 'Hon’ble RBI Governor, Mr. Shaktikanta Das at the 17th KP Hormis Memorable Lecture, Kochi',
    titleMob: 'Hon’ble RBI Governor, Mr. Shaktikanta Das at the 17th KP Hormis Memorable Lecture, Kochi',
    images: [
      {
        img: 'kochi-lecture1.png',
        imgMob: 'kochi-lecturemob-1.png',
        link: 'https://www.youtube.com/embed/U7gp1zJPJqM?si=XXtj2Hzc-ZxjlhX5',
      },
      {
        img: 'kochi-lecture2.png',
        imgMob: 'kochi-lecturemob-2.png',
      },
      {
        img: 'kochi-lecture3.png',
        imgMob: 'kochi-lecturemob-3.png',
      },
      {
        img: 'kochi-lecture4.png',
        imgMob: 'kochi-lecturemob-4.png',
      },
      {
        img: 'kochi-lecture5.png',
        imgMob: 'kochi-lecturemob-5.png',
      },
    ],
  },
  {
    title: '49th IDA Dairy Industry Conference from 16th -18th March 2023, Gandhinagar Gujarat',
    titleMob: '49th IDA Dairy Industry Conference from 16th -18th March 2023, Gandhinagar Gujarat',
    images: [
      {
        img: 'gujrat-web1.png',
        imgMob: 'gujrat-mob-1.png',
        link: 'https://www.youtube.com/embed/JPsySw3X9M8?si=GsHzyLKi_dnXZhr5',
      },
      {
        img: 'gujrat-web2.png',
        imgMob: 'gujrat-mob-2.png',
      },
      {
        img: 'gujrat-web3.png',
        imgMob: 'gujrat-mob-3.png',
      },
      {
        img: 'gujrat-web4.png',
        imgMob: 'gujrat-mob-4.png',
      },
      {
        img: 'gujrat-web5.png',
        imgMob: 'gujrat-mob-5.png',
      },
      {
        img: 'gujrat-web6.png',
        imgMob: 'gujrat-mob-6.png',
      },
      {
        img: 'gujrat-web7.png',
        imgMob: 'gujrat-mob-7.png',
      },
    ],
  },
  {
    title: 'Winner of Nasscom India Fintech Award 2023 in Payments Category',
    titleMob: 'Winner of Nasscom India Fintech Award 2023 in Payments Category',
    images: [
      {
        img: 'nasscom-web1.png',
        imgMob: 'nasscom-mob-1.png',
        link: 'https://www.youtube.com/embed/xJNGXrry1tA?si=IDC1RBOjnfjyptBL',
      },
      {
        img: 'nasscom-web2.png',
        imgMob: 'nasscom-mob-2.png',
      },
      {
        img: 'nasscom-web3.png',
        imgMob: 'nasscom-mob-3.png',
      },
    ],
  },
  {
    title: 'Launch of DGV Platform services at Sarhad Dairy (Kutch) ',
    titleMob: 'Launch of DGV Platform services at Sarhad Dairy (Kutch)  ',
    images: [
      {
        img: 'media1-web1.png',
        imgMob: 'media1-mob1.png',
        link: 'https://www.youtube.com/embed/JPsySw3X9M8?si=2DbAFVvRePMPtiUA',
      },
      {
        img: 'media1-web2.png',
        imgMob: 'media1-mob2.png',
      },
      {
        img: 'media1-web3.png',
        imgMob: 'media1-mob3.png',
      },
      {
        img: 'media1-web4.png',
        imgMob: 'media1-mob4.png',
      },
      {
        img: 'media1-web5.png',
        imgMob: 'media1-mob5.png',
      },
      {
        img: 'media1-web6.png',
        imgMob: 'media1-mob6.png',
      },
    ],
  },
  {
    title: 'Launch of DGV Amul micro ATM at Sarvottam dairy by MD, Chairman & Team of Bhavnagar Milk Union',
    titleMob: 'Launch of DGV Amul micro ATM at Sarvottam dairy by MD, Chairman & Team of Bhavnagar Milk Union',
    images: [
      {
        img: 'media2-web1.png',
        imgMob: 'media2-mob1.png',
        link: 'https://www.youtube.com/embed/mx6xo0AlKYA?si=MEmhMnxMzILx--w_',
      },
      {
        img: 'media2-web2.png',
        imgMob: 'media2-mob2.png',
      },
      {
        img: 'media2-web3.png',
        imgMob: 'media2-mob3.png',
      },
      {
        img: 'media2-web4.png',
        imgMob: 'media2-mob4.png',
      },
      {
        img: 'media2-web5.png',
        imgMob: 'media2-mob5.png',
      },
      {
        img: 'media2-web6.png',
        imgMob: 'media2-mob6.png',
      },
      {
        img: 'media2-web7.png',
        imgMob: 'media2-mob7.png',
      },
    ],
  },
  {
    title: 'Ragavan Venkatesan, Founder- MD & CEO, DGV Speech at India International Dairy Expo 2022',
    titleMob: 'Ragavan Venkatesan, Founder- MD & CEO, DGV Speech at India International Dairy Expo 2022',
    images: [
      {
        img: 'media3-web1.png',
        imgMob: 'media3-mob1.png',
        link: 'https://www.youtube.com/embed/1dYYNjLKQGo?si=S3IAJz77Wk5f179C',
      },
      {
        img: 'media3-web2.png',
        imgMob: 'media3-mob2.png',
      },
      {
        img: 'media3-web3.png',
        imgMob: 'media3-mob3.png',
      },
      {
        img: 'media3-web4.png',
        imgMob: 'media3-mob4.png',
      },
      {
        img: 'media3-web5.png',
        imgMob: 'media3-mob5.png',
      },
    ],
  },
  {
    title: 'Launch of DGV Amul micro ATM at Sursagar dairy Limbdi',
    titleMob: 'Launch of DGV Amul micro ATM at Sursagar dairy Limbdi',
    images: [
      {
        img: 'media4-web1.png',
        imgMob: 'media4-mob1.png',
        link: 'https://www.youtube.com/embed/RX64l2EYTIE?si=sD3bc-5esu_eBKIr',
      },
      {
        img: 'media4-web2.png',
        imgMob: 'media4-mob2.png',
      },
      {
        img: 'media4-web3.png',
        imgMob: 'media4-mob3.png',
      },
      {
        img: 'media4-web4.png',
        imgMob: 'media4-mob4.png',
      },
      {
        img: 'media4-web5.png',
        imgMob: 'media4-mob5.png',
      },
      {
        img: 'media4-web6.png',
        imgMob: 'media4-mob6.png',
      },
      {
        img: 'media4-web7.png',
        imgMob: 'media4-mob7.png',
      },
      {
        img: 'media4-web8.png',
        imgMob: 'media4-mob8.png',
      },
      {
        img: 'media4-web9.png',
        imgMob: 'media4-mob9.png',
      },
      {
        img: 'media4-web10.png',
        imgMob: 'media4-mob10.png',
      },
    ],
  },
  {
    title: 'Dubai Expo 2022',
    titleMob: 'Dubai Expo 2022',
    images: [
      {
        img: 'media5-web1.png',
        imgMob: 'media5-mob1.png',
      },
      {
        img: 'media5-web2.png',
        imgMob: 'media5-mob2.png',
      },
      {
        img: 'media5-web3.png',
        imgMob: 'media5-mob3.png',
      },
      {
        img: 'media5-web4.png',
        imgMob: 'media5-mob4.png',
      },
      {
        img: 'media5-web5.png',
        imgMob: 'media5-mob5.png',
      },
    ],
  },
  {
    title:
      'Union Minister for Fisheries, Animal Husbandry and Dairying, Shri Parshottam Rupala @ DGV - Rajkot, Gujarat',
    titleMob:
      'Union Minister for Fisheries, Animal Husbandry and Dairying, Shri Parshottam Rupala @ DGV - Rajkot, Gujarat',
    images: [
      {
        img: 'media6-web1.png',
        imgMob: 'media6-mob1.png',
      },
      {
        img: 'media6-web2.png',
        imgMob: 'media6-mob2.png',
      },
      {
        img: 'media6-web3.png',
        imgMob: 'media6-mob3.png',
      },
      {
        img: 'media6-web4.png',
        imgMob: 'media6-mob4.png',
      },
      {
        img: 'media6-web5.png',
        imgMob: 'media6-mob5.png',
      },
    ],
  },
];
export const press = [
  {
    press_date: 'December 05, 2023',
    press_desc:
      'Omidyar Network India leads INR 50 crores ($6 million) Series A funding round in Dairy Fintech platform DGV',
    press_img: 'press_pdf.png',
    press_heading: 'View Now',
    press_heading_mobile: 'Read Now',
  },
  {
    press_date: 'November 17, 2022',
    press_desc:
      'Digivriddhi Technologies Private Limited (DGV), India’s first integrated dairy fintech startup and marketplace platform to enable dairy farmer’s access to institutional credit',
    press_img: 'press_pdf.png',
    press_heading: 'View Now',
    press_heading_mobile: 'Read Now',
  },
  {
    press_date: 'October 26, 2022',
    press_desc:
      'DGV launches DGV CONNECT - An integrated dairy marketplace with embedded finance options for cattle discovery and trade',

    press_img: 'press_pdf.png',
    press_heading: 'View Now',
    press_heading_mobile: 'Read Now',
  },
];

export const MEDIA_EVENT_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../assets/images/MediaAndEvent/MediaEventCarousal/media-web5.png') },
  { id: 2, name: '', path: require('../assets/images/MediaAndEvent/MediaEventCarousal/media-web6.png') },
  { id: 3, name: '', path: require('../assets/images/MediaAndEvent/MediaEventCarousal/media-web7.png') },
  { id: 4, name: '', path: require('../assets/images/MediaAndEvent/MediaEventCarousal/media-web1.png') },
  { id: 5, name: '', path: require('../assets/images/MediaAndEvent/MediaEventCarousal/media-web3.png') },
];

// solution page

export const SOLUTION_SLIDER_IMAGES = [
  { id: 1, name: '', path: require('../pages/Solutions/images/solution-web1.png') },
  { id: 2, name: '', path: require('../pages/Solutions/images/solution-web2.png') },
  { id: 3, name: '', path: require('../pages/Solutions/images/solution-web3.png') },
];
export const SOLUTION_INFO = {
  productTitle: 'Solutions for <br/> Dairy-Ecosystem',
  productDescription:
    'We simplified the payment value chain through DGV PAY. Through DGV MONEY we simplified digital diary loans & insurance for milk societies & farmers. DGV created an unique underwriting mechanism to facilitate digital working capital loans to milk societies and Digital cattle loans for the dairy farmers.',
};

export const video = [
  {
    union: [
      {
        url: 'https://www.youtube.com/embed/W39kum43RT4',
        title: 'MD- Sursagar Dairy',
        style: { width: '360px' },
      },
      {
        url: 'https://www.youtube.com/embed/LDPcPptUiBs',
        title: 'Chairman- Dudhsagar Dairy',
        style: { width: '360px' },
      },
      {
        url: 'https://www.youtube.com/embed/OAoc7jJP9Pc',
        title: 'MD- Sarvottam Dairy',
        style: { width: '360px' },
      },
      {
        url: 'https://www.youtube.com/embed/JHX5iXWHznk',
        title: 'GM- Dudhsagar Dairy',
        style: { width: '360px' },
      },
    ],
  },
  {
    societies: [
      {
        url: 'https://www.youtube.com/embed/Dt14l02U3ZI',
        title: 'Secretary- Himmatpur Mandli',
      },
      {
        url: 'https://www.youtube.com/embed/LgLCZQg2Cn4',
        title: 'Secretary-Vansol Mandli',
      },
    ],
  },
  {
    farmers: [
      {
        url: 'https://www.youtube.com/embed/WUDOCQhkT_w',
        title: 'Ambaben',
      },
      {
        url: 'https://www.youtube.com/embed/C3SZGKTx6T0',
        title: 'Ram',
      },
    ],
  },
  {
    partners: [
      {
        url: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
        title: 'RS SODHI',
      },
      {
        url: 'https://www.youtube.com/embed/8PvInu4a_TU',
        title: 'Atul Agarwal',
      },
    ],
  },
];

export const PARTNER_DATA = [
  {
    logoImg: require('../assets/images/partnerAmulLogo.png'),
    partnerInfo:
      'Dr. R S Sodhi, Former MD of GCMMF (AMUL) & President of Indian Dairy Association, talks about DGV platform in providing dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
    url: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
    title: 'RS SODHI',
  },
  {
    logoImg: require('../assets/images/federal_bank.png'),
    partnerInfo:
      'Shalini Warrier, Executive Director at Federal Bank speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
    url: 'https://www.youtube.com/embed/wAOLSJatSes',
    title: 'Shalini Warrier',
    imgClass: 'fedralBank-img',
  },
  {
    logoImg: require('../assets/images/partnerAmulLogo.png'),
    partnerInfo:
      'Atul Agarwal, Senior General Manager Finance at GCMMF (AMUL) addressing challenges faced by dairy farmers & provides solutions to overcome them by using DGV platform',
    url: 'https://www.youtube.com/embed/8PvInu4a_TU',
    title: 'Atul Agarwal',
  },
];

export const HomePageSlide1 = {
  id: 1,
  title: 'About us',
  heading1: 'India’s First Integrated ',
  heading2: 'Dairy Fintech & Marketplace Platform',
  description: 'Re-imagining BFSI & Marketplace Access for 80M Dairy Farmers & <br/> Dairy Micro-enterprises in India.',
  img: require('../assets/images/webHomeBanner3.png'),
  path: ROUTES_PATH.ABOUT_US,
  link: 'https://www.youtube.com/embed/yZPWCLcVQ00?si=7UvlmBg9RDb9tose',
};

export const HomePageSlide3 = {
  id: 3,
  title: 'DGV Speaks',
  description:
    'Showcasing our integrated Dairy Fintech & Marketplace model to Hon’ble RBI Governor, Mr. Shaktikanta Das at the 17th KP Hormis Memorable Lecture, Kochi',
  img: require('../assets/images/DGV-speaks.png'),
  link: 'https://www.youtube.com/embed/U7gp1zJPJqM',
};

export const HomePageSlide4 = {
  id: 4,
  title: 'Partner Speaks',
  description:
    'Dr. R S Sodhi, President of Indian Dairy Association & Former MD of GCMMF (AMUL) , speaks about DGV contribution to the AMUL dairy farmers and milk societies ecosystem',
  img: require('../assets/images/webHomeBanner4.png'),
  link: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
};
export const HomePageSlide5 = {
  id: 5,
  title: 'Partner Speaks',
  description:
    'Shalini Warrier, Executive Director at Federal Bank speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
  img: require('../assets/images/webHomeBanner5.png'),
  link: 'https://www.youtube.com/embed/wAOLSJatSes',
};

export const HomePageSlide6 = {
  id: 6,
  title: 'Overview',
  heading1: 'Snapshot of Indian Dairy Industry ',
  description:
    'India ranks 1st among all countries in both production and consumption of milk.</br>It has the world’s largest dairy herd with over 300+ million cattles.',

  img: require('../assets/images/webHomeBanner1.png'),
  path: ROUTES_PATH.NETWORK,
};

export const HomePageSlideMobile1 = {
  id: 1,
  title: 'About us',
  heading1: 'India’s First Integrated ',
  heading2: 'Dairy Fintech & </br> Marketplace Platform',
  description:
    'Re-imagining BFSI & Marketplace Access <br/> for 80M Dairy Farmers & <br/> Dairy Micro-enterprises in India.',
  img: require('../assets/images/MobHomeBanner3.png'),
  path: ROUTES_PATH.ABOUT_US,
  link: 'https://www.youtube.com/embed/yZPWCLcVQ00?si=7UvlmBg9RDb9tose',
};

export const HomePageSlideMobile3 = {
  id: 3,
  title: 'DGV Speaks',
  description:
    'Showcasing our integrated Dairy Fintech & Marketplace model to Hon’ble RBI Governor, Mr. Shaktikanta Das at the 17th KP Hormis Memorable Lecture, Kochi',
  img: require('../assets/images/DGV-speaks-mobile.png'),
  link: 'https://www.youtube.com/embed/U7gp1zJPJqM',
};

export const HomePageSlideMobile4 = {
  id: 4,
  title: 'Partner Speaks',
  description:
    'Dr. R S Sodhi, President of Indian Dairy Association & Former MD of GCMMF (AMUL) , speaks about DGV contribution to the AMUL dairy farmers and milk societies ecosystem',
  img: require('../assets/images/mobHomeBanner4.png'),
  link: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
};
export const HomePageSlideMobile5 = {
  id: 5,
  title: 'Partner Speaks',
  description:
    'Shalini Warrier, Executive Director at Federal Bank speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
  img: require('../assets/images/mobHomeBanner5.png'),
  link: 'https://www.youtube.com/embed/wAOLSJatSes',
};
export const HomePageSlideMobile6 = {
  id: 6,
  title: 'Overview',
  heading1: 'Snapshot of Indian <br/> Dairy Industry ',
  description:
    'India ranks 1st among all <br/> countries in both production and <br/>consumption of milk. <br/>It has the world’s largest dairy <br/>herd with over 300+ million <br/>cattles.',

  img: require('../assets/images/MobHomeBanner1.png'),
  path: ROUTES_PATH.NETWORK,
};
