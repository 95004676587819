import React from 'react';
import './mdesuccesspopup.css';

export default function MDESuccessPopUp({ setSuccessPopUp, errorMessage }) {
  return (
    <div className="mdesuccesspopup_plot">
      <div className="mdesuccesspopup">
        <div className="success_header">
          <button
            className="_close_success"
            onClick={(e) => {
              setSuccessPopUp(false);
            }}
          ></button>
          <div className={`checkmark ${errorMessage ? 'error' : ''}`}></div>
        </div>
        <div className="success_content">
          {errorMessage ? (
            <>
              <p className="success-msg">Form submission failed !</p>
            </>
          ) : (
            <>
              <p className="success-msg">
                Thank you for your Interest.
                <br /> Our Representative will contact you shortly.
              </p>
            </>
          )}
          <div
            className="_close_pop"
            onClick={(e) => {
              setSuccessPopUp(false);
            }}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
}
