import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const hashParts = window.location.hash.split('#');

  if (hashParts.length > 2) {
    const hashUrl = hashParts.slice(-1)[0];

    const element = document.querySelectorAll(`#${hashUrl}`);

    if (element) {
      element.forEach((elm) => {
        const style = window.getComputedStyle(elm);
        if (style.display !== 'none') {
          const offset = 100;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = elm.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          console.log('visible element  : ', elm);

          elm.scrollIntoView({ top: offsetPosition, behavior: 'smooth' });
        }
      });
    }
  } else {
    window.scrollTo(0, 0);
  }

  return null;
}
