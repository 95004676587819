import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import VideoButton from '../VideoButton/VideoButton';
import { DialogVideo } from '../DialogVideo/DialogVideo';
import './HomePageSlide3.css';

const HomePageSlide3 = ({ HomePageSlide, animated, info, pause, play }) => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [mobileView, setMobileView] = useState(false);

  const { ref } = useInView();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    play();
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);

  return (
    <div className="banner-slide" id={`slide-${HomePageSlide.id}`}>
      <div className="home-banner-img ">
        <img src={HomePageSlide.img} alt="banner" />
      </div>

      <div className="banner-desc-slide3">
        <div
          ref={ref}
          className={
            animated === HomePageSlide.id
              ? 'bottom-mr banner-title animate__animated animate__fadeInDown'
              : 'bottom-mr  banner-title animate__animated animate__fadeOutLeft'
          }
        >
          {HomePageSlide.title}
        </div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'sub-title mob-title animate__animated animate__fadeInUp animate__delay-2s'
              : 'sub-title mob-title animate__animated animate__fadeOutLeft'
          }
          dangerouslySetInnerHTML={{ __html: HomePageSlide.description }}
        ></div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'know-more-slide3 animate__animated animate__fadeInUp animate__delay-3s'
              : 'know-more-slide3 animate__animated animate__fadeOutLeft'
          }
        >
          {' '}
        </div>
        <div
          className={
            animated === HomePageSlide.id
              ? 'u-tube-btn animate__animated animate__fadeInUp animate__delay-4s'
              : 'u-tube-btn animate__animated animate__fadeOutLeft'
          }
          onClick={pause}
        >
          <VideoButton handleClickOpen={() => handleClickOpen(HomePageSlide.link)}></VideoButton>
        </div>
        {open && <DialogVideo title={'partner'} link={activeLink} handleClose={handleClose} />}
      </div>
    </div>
  );
};

export default HomePageSlide3;
