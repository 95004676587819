import React from 'react';
import './SellerBenefit.css';

const SellerBenefit = ({ Benefits = [] }) => {
  return (
    <>
      {Benefits.map((data) => (
        <div key={data.id} className="benefit-heading">
          <img src={require(`../../assets/images/${data.logo}.png`)} className="benefit-icon" alt="sellerBenefit"></img>
          <div className="benefit-text">{data.benefit}</div>
        </div>
      ))}
    </>
  );
};

export default SellerBenefit;
