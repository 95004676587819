import React from 'react';
import videoicon from '../../assets/images/play-video.png';
import './VideoButton.css';

export default function VideoButton({ title = '', handleClickOpen }) {
  return (
    <>
      <button className="_video_button" onClick={handleClickOpen}>
        <div className="play-icon">
          <img src={videoicon} alt="videoicon" />
        </div>
        <p className="watch-txt">Watch Now</p>
      </button>
      <p>{title}</p>
    </>
  );
}
