export const NETWORK_PRESENCE_DATA = [
  {
    img: 'milk_union.png',
    des: 'No. of Union',
    num: 14,
    delay: '1s',
  },
  {
    img: 'milk_car.png',
    des: 'No. of Society',
    num: 1825,
    delay: '2s',
  },
  {
    img: 'cow.png',
    des: 'No. of Bovines Listed',
    num: 500,
    delay: '3s',
  },
  {
    img: 'farmer.png',
    des: 'No. of farmers',
    num: 100000,
    delay: '4s',
  },
  {
    img: 'rupee.png',
    des: 'Loan Distributed (INR)',
    num: 20000000,
    delay: '5s',
  },
];

export const NETWORK_STATE_PRESENCE = {
  dairyUnionCount: 14,
  totalDairyUnion: 18,
  stateName: 'GUJARAT',
};
