import React from 'react';
import Slider from 'react-slick';
import { ROUTES_PATH } from '../../utils/constants';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LifeAtDGV.css';

const LifeAtDGV = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const LifeAtDGVMainCarosal = [
    {
      id: '1',
      img: 'main-web1.png',
      hash: 'trek',
    },
    {
      id: '2',
      img: 'main-web2.png',
      hash: 'thirdAnniversary',
    },
    {
      id: '3',
      img: 'main-web9.png',
      hash: 'training',
    },
    {
      id: '4',
      img: 'main-web3.png',
      hash: 'offsite',
    },
    {
      id: '5',
      img: 'main-web4.png',
      hash: 'annual',
    },
    {
      id: '6',
      img: 'main-web5.png',
      hash: 'holi',
    },
    {
      id: '7',
      img: 'main-web6.png',
      hash: 'womenday',
    },
    {
      id: '8',
      img: 'main-web7.png',
      hash: 'corporate',
    },
    {
      id: '9',
      img: 'main-web8.png',
      hash: 'anniversary',
    },
  ];

  return (
    <div>
      <div className="life-at-DGV">
        <div className="life-at-DGV-main-wrapper">
          <h1>Life at DGV</h1>
          <div className="life-at-dgv-main-slick-slider">
            <Slider {...settings}>
              {LifeAtDGVMainCarosal.map((item) => {
                return (
                  <Link to={ROUTES_PATH.LIFE_AT_DGV + '#' + item.hash} key={item.id}>
                    <div className="life-at-dgv-main-slider">
                      <img
                        className="life-at-dgv-main-slider-img"
                        src={require(`./images/${item.img}`)}
                        alt="DGV_image"
                      />
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
          <div className="lifeAtDGV-more">
            <Link to={ROUTES_PATH.LIFE_AT_DGV}>View More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeAtDGV;
