import React, { useState } from 'react';
import FormTextArea from '../FormComponents/FormTextArea/FormTextArea';
import FormButton from '../FormComponents/FormButton/FormButton';
import FormTextInput from '../FormComponents/FormTextInput/FormTextInput';
import ErrorIcon from '../../assets/images/errorIcon.png';
import { Validate } from '../FormValidation/FormValidation';
import { BASE_URL } from '../../utils/api';
import './DropAMessage.css';

const DropAMessage = (props) => {
  const [user, setUser] = useState({
    fullName: '',
    email: '',
    mobileNo: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isvaliddata = Validate(user);
    if (Object.keys(isvaliddata).length === 0) {
      setUser({
        fullName: '',
        email: '',
        mobileNo: '',
        subject: '',
        message: '',
      });

      setErrors('');

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(`${BASE_URL}contact`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.error(err);
          alert(err?.response?.data?.message);
        });
    } else {
      setErrors(isvaliddata);
    }
  };

  return (
    <>
      <div className="drop-a-message-main">
        <div className="form-header">Drop Us A Message</div>
        <div className="input_field">
          <div className="contact-us-form-text-field">
            <FormTextInput
              onChange={handleChange}
              value={user.fullName}
              name={'fullName'}
              placeholder="Your Full Name*"
              type="text"
              fClass={'fClass'}
              error={
                errors.fullName ? (
                  <div className="errorTexts">
                    <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                    {errors?.fullName}
                  </div>
                ) : (
                  ''
                )
              }
            />
          </div>

          <div className="contact-us-form-text-field">
            <FormTextInput
              onChange={handleChange}
              value={user.mobileNo}
              name={'mobileNo'}
              placeholder="Mobile Number*"
              type="number"
              fClass={'fClass'}
              error={
                errors.mobileNo ? (
                  <div className="errorTexts">
                    <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                    {errors?.mobileNo}
                  </div>
                ) : (
                  ''
                )
              }
            />
          </div>
          <div className="contact-us-form-text-field">
            <FormTextInput
              onChange={handleChange}
              value={user.email}
              name={'email'}
              placeholder="Email ID*"
              type="email"
              fClass={'fClass'}
              error={
                errors.email ? (
                  <div className="errorTexts">
                    <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                    {errors?.email}
                  </div>
                ) : (
                  ''
                )
              }
            ></FormTextInput>
          </div>

          <div className="contact-us-form-text-field">
            <FormTextInput
              onChange={handleChange}
              value={user.subject}
              name={'subject'}
              placeholder="Subject*"
              type="text"
              fClass={'fClass'}
              error={
                errors.subject ? (
                  <div className="errorTexts">
                    <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                    {errors?.subject}
                  </div>
                ) : (
                  ''
                )
              }
            />
          </div>

          <div className="contact-us-form-text-field">
            <FormTextArea
              onChange={handleChange}
              value={user.message}
              name={'message'}
              placeholder="Message*"
              type="text"
              rows="4"
              fClass={'fClass'}
              error={
                errors.message ? (
                  <div className="errorTexts">
                    <img src={ErrorIcon} alt="errorIcon" className="errorImg"></img>
                    {errors?.message}
                  </div>
                ) : (
                  ''
                )
              }
            />
          </div>
          <div className="contactUs-submit">
            <FormButton title="Submit" handleSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DropAMessage;
