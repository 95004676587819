import React from 'react';
import './PopUpComponent.css';

export default function PopUpComponent(props) {
  return (
    <div className="_popup_component">
      {/* <div className="popup-overlay"> */}
      {/* <h1>Popup</h1> */}
      {props.children}
      {/* </div> */}
    </div>
  );
}
