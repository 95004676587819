import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES_PATH } from '../../utils/constants';
import './CareerIntroSection.css';
const CareerIntroSection = () => {
  return (
    <div className="career-intro-section">
      <div className="career-intro-heading">Be the Change!</div>
      <div>
        <div className="career-intro-sec-heading1">Join us & digitalize the roadrails of Dairy Sector.</div>
        <div className="career-intro-sec-heading1">
          We are a team of young, passionate people who celebrate working together and challenging status quo through
          innovative product proposition.
        </div>

        <div className="career-intro-sec-heading1">
          To know more about Life at DGV,{' '}
          <Link to={ROUTES_PATH.LIFE_AT_DGV} className="Life-dgv-link">
            CLICK HERE
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CareerIntroSection;
