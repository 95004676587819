import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './home.css';
import HomePageSlide from '../../components/HomePageSlide/HomePageSlide';
import HomePageSlide2 from '../../components/HomePageSlide2/HomePageSlide2';
import HomePageSlideThree from '../../components/HomePageSlide3/HomePageSlide3';
import {
  HomePageSlide1,
  HomePageSlide3,
  HomePageSlide4,
  HomePageSlide5,
  HomePageSlide6,
  HomePageSlideMobile1,
  HomePageSlideMobile3,
  HomePageSlideMobile4,
  HomePageSlideMobile5,
  HomePageSlideMobile6,
} from '../../utils/constants';

const Home = () => {
  const [mobileView, setMobileView] = useState(false);
  const [animated, setAnimated] = useState(0);

  const inputRef = useRef(null);
  const playAndNext = () => {
    // setAnimated(next);

    inputRef.current.slickPlay();

    inputRef.current.slickNext();
  };
  const play = (next) => {
    inputRef.current.slickPlay();
  };
  const pause = () => {
    inputRef.current.slickPause();
  };

  const beforeChange = (prev, next) => {
    setAnimated(next);
  };

  const afterChange = (current) => {
    if (current === 1) {
      pause();
      setTimeout(() => play(current), 4000);
    }
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 14000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    pauseOnFocus: false,
    waitForAnimate: true,
    beforeChange,
    afterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // autoplaySpeed: 15000,
          dots: true,
          arrows: false,
          fade: true,
          pauseOnDotsHover: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);

  return (
    <div>
      <div className="home-banner-plot">
        <Slider ref={inputRef} {...settings}>
          <HomePageSlide
            HomePageSlide={mobileView ? HomePageSlide1 : HomePageSlideMobile1}
            animated={animated + 1}
            watchNow={false}
          ></HomePageSlide>
          <HomePageSlide2 animated={animated}></HomePageSlide2>
          <HomePageSlideThree
            HomePageSlide={mobileView ? HomePageSlide3 : HomePageSlideMobile3}
            pause={pause}
            play={playAndNext}
            animated={animated + 1}
          ></HomePageSlideThree>
          <HomePageSlideThree
            HomePageSlide={mobileView ? HomePageSlide4 : HomePageSlideMobile4}
            pause={pause}
            play={playAndNext}
            animated={animated + 1}
          ></HomePageSlideThree>

          <HomePageSlideThree
            HomePageSlide={mobileView ? HomePageSlide5 : HomePageSlideMobile5}
            pause={pause}
            play={playAndNext}
            animated={animated + 1}
          ></HomePageSlideThree>

          <HomePageSlide
            HomePageSlide={mobileView ? HomePageSlide6 : HomePageSlideMobile6}
            animated={animated + 1}
            watchNow={false}
          ></HomePageSlide>
        </Slider>
      </div>
    </div>
  );
};

export default Home;
