import React from 'react';
import { ABOUT_US_SLIDER_IMAGES, WHO_WE_ARE } from '../../../utils/constants';
import MeetOurCEO from '../../../components/MeetOurCEO/MeetOurCEO';
import VisionMission from '../../../components/VIsionMisson/VisionMission';
import InvestorPartner from '../../../components/InvestorPartner/InvestorPartner';
import CoreManagementTeam from '../../../components/CoreManagementTeam/CoreManagementTeam';
import BoardOfDirector from '../../../components/BoardOfDirector/BoardOfDirector';
import LifeAtDGV from '../../../components/lifeAtDGV/LifeAtDGV';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="aboutUs-main-container">
      <div className="AboutUs_page-padding" id="who-we-are">
        <ProductInfoPlot info={WHO_WE_ARE} images={ABOUT_US_SLIDER_IMAGES} />
      </div>

      <div className="meet-our-CEO-section" id="ceo-desk">
        <MeetOurCEO />
      </div>

      <div className="vision-mission-section" id="vision-mission">
        <VisionMission />
      </div>

      <div className="investor-partner" id="investor">
        <InvestorPartner />
      </div>

      <div className="Board-of-director" id="board-of-director">
        <BoardOfDirector />
      </div>

      <div className="core-management-team" id="management-team">
        <CoreManagementTeam />
      </div>

      <div className="life-at-dgv" id="life-at-dgv">
        <LifeAtDGV />
      </div>
    </div>
  );
};

export default AboutUs;
