import React from 'react';

import { SOLUTION_INFO, SOLUTION_SLIDER_IMAGES } from '../../utils/constants';
import ProductInfoPlot from '../../components/ProductInfoPlot/ProductInfoPlot';
import Solutions from '../../components/SolutionPage/Solutions';

const Solution = () => {
  return (
    <div>
      <div className="solution-page-padding">
        <ProductInfoPlot info={SOLUTION_INFO} images={SOLUTION_SLIDER_IMAGES} />
      </div>

      <div className='solution-page-comp'>
        <Solutions/>
      </div>
    </div>
  );
};

export default Solution;
