import React from 'react';
import Slider from 'react-slick';
import { EmployeeTestimonialArr } from '../../utils/constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialCarousal.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToScroll: 1,
  slidesToShow: 2,
  autoplay: true,

  responsive: [
    {
      breakpoint: 991.98,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialCarousal = () => {
  return (
    <div className="testimonial-slider-dots">
      <Slider {...settings}>
        {EmployeeTestimonialArr.map((employee) => (
          <div key={employee.id} className="testimonial-container">
            <div className="testimonial-bg">
              <div className="testimonial-employee-img">
                <img src={employee.imgPath} className="avatar-img" alt="employee"></img>
              </div>
              <div className="testimonial-employee-info-container">
                <div className="name-heading">{employee.name}</div>
                <div className="post-heading">{employee.designation}</div>
                <div className="experience-description">{employee.experience}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousal;
