import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FormButton from '../FormComponents/FormButton/FormButton';
import './JobOpeningAccordion.css';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderRadius: '5px',
  margin: '30px 0',
  border: `2px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#074E8D' }} />}
  />
))(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function JobOpeningAccordion({ job, showApplication, setExpanded, expanded }) {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Accordion expanded={expanded === job.jobId} onChange={handleChange(job.jobId)}>
        <AccordionSummary>
          <div className="job-title-accordion">{job.title}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="job-details" dangerouslySetInnerHTML={{ __html: job.details }}></div>
          <div className="job-accordian-btn-center">
            <FormButton title="Apply" handleSubmit={() => showApplication(job.title)} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
