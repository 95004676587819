import React from 'react';
import './OfferingsCircle.css';

const OfferingsCircles = ({ props }) => {
  return (
    <div className="OfferingsCircles-wrapper">
      <img className="offeringCircles-logo" src={props?.circleImage}></img>
      <div className="offeringCircles-heading" dangerouslySetInnerHTML={{ __html: props?.heading }}>
        {/* {props?.heading} */}
      </div>
    </div>
  );
};

export default OfferingsCircles;
