import React from 'react';
import CloseButton from '../FormComponents/CloseButton/CloseButton';
import FormButton from '../FormComponents/FormButton/FormButton';
import './ApplyConfirm.css';

const ApplyConfirm = ({ handleClose, result }) => {
  const jobId = result ? result.jobReferenceNo : '';

  const copyToClipBoard = (e) => {
    navigator.clipboard.writeText(jobId);
  };

  return (
    <div className="apply_confirm_popup">
      <div className="apply_confirm_header">
        <CloseButton class={'_close-apply'} handleClose={handleClose} />
        <div className="green_label"></div>
      </div>
      <div className="apply_confirm_content">
        <div className="thanks-text">Thank you for applying for</div>
        <div className="applied-position">{result?.category}</div>
        <div className="ref_id_title">Your Application Reference ID</div>
        <div className="ref-id">
          {jobId}
          <button className="clipboard" onClick={copyToClipBoard}></button>
        </div>
        <FormButton title="Close" handleSubmit={handleClose} />
      </div>
    </div>
  );
};

export default ApplyConfirm;
