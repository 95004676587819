import React from 'react';
import perks_banner_left_img from '../../assets/images/perks_banner_left.png';
import { CAREER_PERKS_LOGOS, Mobile_perks_logos } from '../../utils/constants';
import CareerPerksLogos from '../../components/CareerPerksLogos';
import './CareerPerksBanner.css';

const CareerPerksBanner = () => {
  return (
    <div className="perks-banner">
      <div className="perks-heading">
        Perks<span style={{ color: '#ED1C24' }}>@</span>DGV
      </div>
      <div className="image-logos-container">
        <div className="perks-banner-left">
          <img src={perks_banner_left_img} alt="perks-banner"></img>
        </div>
        <div className="perks-banner-right">
          {CAREER_PERKS_LOGOS.map((logosData) => (
            <div key={logosData.id} className={` ${logosData.perkClass}`}>
              {logosData.children.map((item) => (
                <CareerPerksLogos props={item} key={item.id}></CareerPerksLogos>
              ))}
            </div>
          ))}

          <div className="mobile-logos-container">
            {Mobile_perks_logos.map((logos) => (
              <CareerPerksLogos props={logos} key={logos.id}></CareerPerksLogos>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPerksBanner;
