import CloseButton from '../FormComponents/CloseButton/CloseButton';
import FormButton from '../FormComponents/FormButton/FormButton';
import './ApplyFailure.css';

const ApplyFailure = ({ handleClose, result }) => {
  return (
    <div className="apply_failure_popup">
      <div className="apply_failure_header">
        <CloseButton class={'_close-apply'} handleClose={handleClose} />
        <div className="red-flag"></div>
      </div>
      <div className="apply_failure_content">
        <div className="fail-text">Your form was not submitted. Please try again later</div>
        <FormButton title="Close" handleSubmit={handleClose} />
      </div>
    </div>
  );
};

export default ApplyFailure;
