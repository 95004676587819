import React from 'react';
import BenefitHeading from '../../../components/BenefitHeading';
import MoneyLoan from '../../../components/MoneyLoan/MoneyLoan';
import Header from '../../../components/Header';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Footer from '../../../components/Footer';
import animationImgWeb from '../../../assets/images/DGVMoneyWeb.gif';
import animationImgMobile from '../../../assets/images/DGVMoneyMobile.gif';
import { LoanTypesData, MONEY_SLIDER_IMAGES, PRODUCT_MONEY_INFO } from '../../../utils/constants';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import './DGVMoney.css';

const DGVMoney = () => {
  return (
    <div className="money-container desktop-view">
      <Header />
      <BreadCrumb />
      <div className="page-container page-content">
        <ProductInfoPlot info={PRODUCT_MONEY_INFO} images={MONEY_SLIDER_IMAGES} />
      </div>
      <div className="image-container money-image-web">
        <img src={animationImgWeb} alt="connect animation" />
      </div>
      <div className="image-container money-image-mobile">
        <img src={animationImgMobile} alt="connect animation" />
      </div>
      <div className="page-container">
        <BenefitHeading />
        <MoneyLoan LoanTypes={LoanTypesData} />
      </div>
      <Footer />
    </div>
  );
};

export default DGVMoney;
