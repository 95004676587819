import React from 'react';
import './MoneyLoanBlock.css';

const MoneyLoanBlock = ({ loanItem, inView, blockClass = '', fadeInLeft, fadeInRight }) => {
  return (
    <div className="money_loan_block">
      <div className={inView ? 'Money_loan_icon animate__animated animate__zoomIn' : 'Money_loan_icon'}>
        <img src={require(`../../assets/images/${loanItem.icon}`)} alt={loanItem.icontitle} />
      </div>
      <div className={inView ? 'money_loan_title animate__animated animate__zoomIn' : 'money_loan_title '}>
        {loanItem.loantitle}
      </div>
      <div className={inView ? 'money_loan_desc animate__animated animate__zoomIn' : 'money_loan_desc'}>
        {loanItem.loandesc}
      </div>
      <div className={`money_loan_wrapper ${blockClass}`}>
        <div className={`money_loan_img ${inView && fadeInLeft}`}>
          <img
            src={require(`../../assets/images/${loanItem.loanimg}`)}
            className={loanItem.imgWidthClass}
            alt={loanItem.loanimgtitle}
          />
        </div>
        <div className={`money_loan_detail ${inView && fadeInRight}`}>
          <div className="detail_title">Benefits</div>
          {/* <div className={multipleBenefits ? 'web-detail-wrap' : ''}> */}
          <ul className="detail_wrap">
            {loanItem.benefitslist.map((listItem, key) => (
              <li className="detail_points" key={key}>
                {listItem.list}
              </li>
            ))}
          </ul>
          {/* </div> */}
          {/* <div className={multipleBenefits ? 'mobile-detail-wrap' : ''}>
            <ul className="detail_wrap">
              {loanItem.mobileBenefitsList?.map((listItem, key) => (
                <li className="detail_points" key={key}>
                  {listItem.list}
                </li>
              ))}
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MoneyLoanBlock;
