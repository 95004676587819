import React from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import SellerBenefit from '../../../components/SellerBenefit';
import QRCode from '../../../components/QRCode';
import {
  CONNECT_SLIDER_IMAGES,
  PRODUCT_CONNECT_INFO,
  SellerBenefitData,
  BuyerBenefitData,
} from '../../../utils/constants';
import Seller from '../../../assets/images/seller.png';
import Buyer from '../../../assets/images/Buyer.svg';
import InfoImage from '../../../components/InfoImage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import animationImgWeb from '../../../assets/images/DGV Connect - Web.gif';
import animationImgMobile from '../../../assets/images/DGV Connect - Mobile.gif';
import { useInView } from 'react-intersection-observer';
import './DGVConnect.css';

const DGVConnect = () => {
  const [ref, inView] = useInView({});
  const [ref2, inView2] = useInView({});

  return (
    <div className="main-container  desktop-view ">
      <Header />
      <BreadCrumb />
      <div className="page-container page-content">
        {/* <div className="product-info-box">
          <ProductDescription info={PRODUCT_CONNECT_INFO}></ProductDescription>
          <div className="slider">
            <Carousel images={CONNECT_SLIDER_IMAGES} />
          </div>
        </div> */}
        <ProductInfoPlot info={PRODUCT_CONNECT_INFO} images={CONNECT_SLIDER_IMAGES} />
      </div>
      <div className="image-container money-image-web">
        <img src={animationImgWeb} alt="connect animation" />
      </div>
      <div className="image-container money-image-mobile">
        <img src={animationImgMobile} alt="connect animation" />
      </div>
      <QRCode />
      <div className="page-container">
        <div
          ref={ref}
          className={inView ? 'connect-title-text animate__animated animate__zoomIn ' : 'connect-title-text'}
        >
          Benefits
        </div>

        <div>
          <div className="benefit-Container">
            <div className={inView ? 'benefit-image animate__animated animate__fadeInLeft  ' : 'benefit-image'}>
              <InfoImage props={Seller}></InfoImage>
            </div>
            <div className={inView ? 'benefit-title animate__animated animate__fadeInRight' : 'benefit-title '}>
              <SellerBenefit Benefits={SellerBenefitData}></SellerBenefit>
            </div>
          </div>

          <div ref={ref2} className="benefit-Container m-top1 ">
            <div className={inView2 ? 'benefit-title animate__animated animate__fadeInLeft ' : 'benefit-title'}>
              <SellerBenefit Benefits={BuyerBenefitData}></SellerBenefit>
            </div>
            <div className={inView2 ? 'benefit-image animate__animated animate__fadeInRight ' : 'benefit-image '}>
              <InfoImage props={Buyer}></InfoImage>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DGVConnect;
