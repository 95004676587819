import React from 'react';
import { Outlet } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MDEPopup from '../components/MDEPopup';

const MainLayout = ({ children }) => {
  return (
    <div className="desktop-view">
      <Header />
      <BreadCrumb />
      {/* <MDEPopup /> */}
      <div className="page-container page-content">{children ? children : <Outlet />}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
