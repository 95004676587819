import React from 'react';
import { OUR_NETWORK, NETWORK_SLIDER_IMAGES } from '../../utils/constants';
import StrategicPartners from '../../components/StratergicPartners/StrategicPartners';
import DataPresence from '../../components/DataPresence/DataPresence';
import CardOverview from '../../components/DairyOverview/Overview';
import ProductInfoPlot from '../../components/ProductInfoPlot/ProductInfoPlot';
import './OurNetwork.css';

const OurNetwork = () => {
  return (
    <div className="network-main-container">
      <ProductInfoPlot info={OUR_NETWORK} images={NETWORK_SLIDER_IMAGES} />

      <div className="DataPresence-section">
        <DataPresence />
      </div>

      <div className="Strategic-partners-section">
        <StrategicPartners />
      </div>
      <div className="CardOverview-section">
        <CardOverview />
      </div>
    </div>
  );
};

export default OurNetwork;
