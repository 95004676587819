import React, { useState } from 'react';
import { DialogVideo } from '../DialogVideo/DialogVideo';
import VideoButton from '../VideoButton/VideoButton';
import './ProductDescription.css';

const ProductDescription = ({ info }) => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="product-content">
      <div className="product-content-wrapper">
        <div className="product-title" dangerouslySetInnerHTML={{ __html: info.productTitle }}></div>
        <div className="product-description" dangerouslySetInnerHTML={{ __html: info.productDescription }}></div>
      </div>
      {info.youTubeBtn && (
        <div className="Utube-btn-container">
          {info.videoTitle?.map((btn, i) => (
            <div key={btn.id} className="youtube-btn">
              <VideoButton titles={btn.language} handleClickOpen={() => handleClickOpen(btn.link)}></VideoButton>
              <p className="lang-text">{btn.language}</p>
            </div>
          ))}
        </div>
      )}
      {open && <DialogVideo title={info.videoTitle} link={activeLink} handleClose={handleClose} />}
    </div>
  );
};

export default ProductDescription;
