import React, { useState, useEffect } from 'react';
import { DialogVideo } from '../DialogVideo/DialogVideo';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { media, press, EventData } from '../../utils/constants';
import NovemberDoc from '../../assets/documents/17November.pdf';
import OctoberDoc from '../../assets/documents/26October.pdf';
import DecemberDoc from '../../assets/documents/05December.pdf';
import './MediaAndEvents.css';

const MediaAndEvents = (props) => {
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(true);
  const [tab3, setTab3] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogVideoData, setDialogVideoData] = useState({ link: '' });
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width:1024px)').matches);

  useEffect(() => {
    window.matchMedia('(min-width: 1024px)').addEventListener('change', (e) => setIsDesktop(e.matches));
  }, []);

  const onDocumentClick = (event, date) => {
    event.stopPropagation();
    if (date === 'November 17, 2022') {
      window.open(NovemberDoc);
    } else if (date === 'October 26, 2022') {
      window.open(OctoberDoc);
    } else if ('December 05, 2023') {
      window.open(DecemberDoc);
    }
  };
  const eventSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    adaptiveHeight: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: 'linear',
    // initialSlide: 'slide',

    responsive: [
      {
        breakpoint: 580,
        settings: {
          adaptiveHeight: true,
          variableWidth: true,
          slidesToShow: 1.5,
        },
      },
    ],
  };

  return (
    <div>
      <div className="media-events-wrapper">
        <section>
          <div className="media-section">
            <div
              className={`media ${tab1 ? 'active' : ''}`}
              onClick={() => {
                setTab1(true);
                setTab2(false);
                setTab3(false);
              }}
            >
              Media
            </div>
            <div
              className={`media ${tab2 ? 'active' : ''}`}
              onClick={() => {
                setTab2(true);
                setTab1(false);
                setTab3(false);
              }}
            >
              Events
            </div>
            <div
              className={`media ${tab3 ? 'active' : ''}`}
              onClick={() => {
                setTab3(true);
                setTab2(false);
                setTab1(false);
              }}
            >
              Press Release
            </div>
          </div>

          <div className="media-mobile mobile-active">
            <div className="media-name">Media</div>
            <div
              className="media-event-arrow"
              onClick={() => {
                setTab1(!tab1);
                setTab2(false);
                setTab3(false);
              }}
            >
              <img src={require(`./media_events/${tab1 ? 'up.png' : 'down.png'}`)} alt="arrows" />
            </div>
          </div>
          <div
            className="media-box"
            style={{
              display: tab1 ? 'grid' : 'none',
            }}
          >
            {media.map((item, i) => {
              return (
                <div className="media-main" key={i}>
                  <div className="media-img">
                    <img src={require(`./images/${item.img}`)} alt="media_picture" />
                  </div>
                  <div className="MediaAndEvent-desc">{item.desc}</div>
                  <div className="media_watch_now">
                    {item.sub_heading === 'Watch Now' ? (
                      <div
                        className="watch_now_desc"
                        onClick={() => {
                          if (item.link) {
                            setOpenDialog(true);
                            setDialogVideoData({ link: item.link });
                          }
                        }}
                      >
                        {item.sub_heading}
                      </div>
                    ) : (
                      <a href={item.link} className="watch_now_desc" target="_blank" rel="noreferrer">
                        {item.sub_heading}
                      </a>
                    )}
                    {item.sub_heading === 'Watch Now' && (
                      <div className="watch_now_icon" style={{ marginTop: '3px' }}>
                        <img src={require(`./media_events/${item.icon}`)} alt="watch_now_icon" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {/* DialogVideo component */}
            {openDialog && (
              <DialogVideo
                title={dialogVideoData.title}
                link={dialogVideoData.link}
                handleClose={() => setOpenDialog(false)}
              />
            )}
          </div>
          <div className="media-mobile mobile-active">
            <div className="media-name">Events</div>
            <div
              className="media-event-arrow"
              onClick={() => {
                setTab1(false);
                setTab2(!tab2);
                setTab3(false);
              }}
            >
              <img src={require(`./media_events/${tab2 ? 'up.png' : 'down.png'}`)} alt="arrows" />
            </div>
          </div>

          {/* ///////////////////////////////Event ////////////////////////////////////// */}

          <div className="events-section" style={{ display: tab2 ? 'block' : 'none' }}>
            {tab2 && (
              <>
                {EventData?.map((data, index) => {
                  return (
                    <div className="event-box" key={index}>
                      {isDesktop ? (
                        <div className="event-name">{data?.title}</div>
                      ) : (
                        <div className="event-name">{data?.titleMob}</div>
                      )}
                      <Slider {...eventSettings}>
                        {data?.images?.map((item, index) => (
                          <div
                            className="event-image"
                            key={index}
                            onClick={() => {
                              if (item.link) {
                                setOpenDialog(true);
                                setDialogVideoData({ link: item.link });
                              } else {
                                props.setCelebImg(data?.images);
                                props.setIsClickCeleb(true);
                              }
                            }}
                          >
                            {isDesktop ? (
                              <img src={require(`./images/${item?.img}`)} alt="event_image" />
                            ) : (
                              <img src={require(`./images/${item?.imgMob}`)} alt="event_image" />
                            )}
                          </div>
                        ))}
                      </Slider>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {openDialog && <DialogVideo link={dialogVideoData.link} handleClose={() => setOpenDialog(false)} />}
          {/* ///////////////////////////////////// */}

          <div className="media-mobile mobile-active">
            <div className="media-name">Press Release</div>
            <div
              className="media-event-arrow"
              onClick={() => {
                setTab1(false);
                setTab2(false);
                setTab3(!tab3);
              }}
            >
              <img src={require(`./media_events/${tab3 ? 'up.png' : 'down.png'}`)} alt="arrows" />
            </div>
          </div>
          <div className="press-box" style={{ display: tab3 ? 'block' : 'none' }}>
            {press.map((item, i) => {
              return (
                <div className="press-container" key={i}>
                  <div className="press">
                    {/* <div className='press-date'>{item.press_date}</div> */}
                    <div className="press-desc">
                      <div className="press-date">{item.press_date}</div>
                      {item.press_desc}
                    </div>
                    <div className="press-pdf" style={{ cursor: 'pointer' }}>
                      <div className="pdf-img" onClick={(event) => onDocumentClick(event, item.press_date)}>
                        <img src={require(`./media_events/${item.press_img}`)} alt="pdf" />
                      </div>
                      <div className="pdf-heading" onClick={(event) => onDocumentClick(event, item.press_date)}>
                        {item.press_heading}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default MediaAndEvents;
