import React, { useState } from 'react';
import Carousel from '../../../components/Carousel';
import { CAREER_SLIDER_IMAGES } from '../../../utils/constants';
import CareerPerksBanner from '../../../components/CareerPerksBanner';
import TestimonialCarousal from '../../../components/TestimonialCarousal';
import JobOpeningAccordion from '../../../components/JobOpeningAccordion';
import { JobVacancies } from '../../../data/vacancy';
import DisclaimerSection from '../../../components/DisclaimerSection/DisclaimerSection';
import CareerIntroSection from '../../../components/CareerIntroSection/CareerIntroSection';
import PopUpComponent from '../../../components/PopUpComponent';
import JobPopUp from '../../../components/JobPopUp';
import ApplyConfirm from '../../../components/ApplyConfirm';
import CarrersApplyJob from '../../../components/CarrersApplyJob';
import ApplyFailure from '../../../components/ApplyFailure/ApplyFailure';
import SpinnerImg from '../../../assets/images/Careers/LoadingIndicator.gif';
import './Careers.css';

const Careers = () => {
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const [showConfirmForm, setShowConfirmForm] = useState(false);
  const [confirmResult, setConfirmResult] = useState();
  const [showJobTitle, setShowJobTitle] = useState();
  const [expanded, setExpanded] = useState(false);
  const [showFailureForm, setShowFailureForm] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const showApplication = (data) => {
    setShowApplicationForm(true);
    setShowJobTitle(data);
    document.body.style.overflow = 'hidden';
  };
  const showSpinnerPopUp = () => {
    setShowApplicationForm(false);
    setSpinner(true);
  };
  const showConfirmPopUp = (result) => {
    setSpinner(false);
    setConfirmResult(result);
    setShowConfirmForm(true);
  };

  const showFailurePopUp = () => {
    setSpinner(false);
    setShowFailureForm(true);
  };

  const closeForm = () => {
    setShowApplicationForm(false);
    setShowConfirmForm(false);
    setShowFailureForm(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <div className="career-content">
      <div className="carrer-info-plot">
        <CareerIntroSection></CareerIntroSection>
        <Carousel images={CAREER_SLIDER_IMAGES} />
      </div>
      <CareerPerksBanner></CareerPerksBanner>
      <div className="heading-text">Employee Testimonial</div>
      <TestimonialCarousal />
      <CarrersApplyJob showDropDownApp={showApplication}></CarrersApplyJob>

      <div className="openposition-heading">Open Positions</div>
      {JobVacancies.map((job) => (
        <div key={job.id}>
          <JobOpeningAccordion
            job={job}
            showApplication={showApplication}
            setExpanded={setExpanded}
            expanded={expanded}
          ></JobOpeningAccordion>
        </div>
      ))}
      {showApplicationForm && (
        <PopUpComponent>
          <JobPopUp
            showJobTitle={showJobTitle}
            handleClose={closeForm}
            handleFormSubmit={showConfirmPopUp}
            handleFormFailure={showFailurePopUp}
            spinnerPopUp={showSpinnerPopUp}
          />
        </PopUpComponent>
      )}
      {showConfirmForm && (
        <PopUpComponent>
          <ApplyConfirm handleClose={closeForm} result={confirmResult} />
        </PopUpComponent>
      )}
      {showFailureForm && (
        <PopUpComponent>
          <ApplyFailure handleClose={closeForm} />
        </PopUpComponent>
      )}
      {spinner && (
        <PopUpComponent>
          <img src={SpinnerImg} alt="spinner" className="spinner-style"></img>
        </PopUpComponent>
      )}
      <DisclaimerSection />
    </div>
  );
};

export default Careers;
