import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { PARTNER_DATA } from '../../utils/constants';
import './PartnerTabSlider.css';
const PartnerTabSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div div className="partners-company-container">
      <Slider {...settings}>
        {PARTNER_DATA.map((data, i) => (
          <div key={i + 1} className="partner-all-info-content">
            <div className={data?.imgClass ? data.imgClass : 'partner-company-logo'}>
              <img src={data.logoImg} alt="PartnerLogo"></img>
            </div>
            <div className="partner-words">{data.partnerInfo}</div>
            <div className="video-content-partnerInfo">
              <iframe className="video-partner-tab" src={data.url} title={data.title} allow="fullscreen"></iframe>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnerTabSlider;
