import React from 'react';
import './DisclaimerSection.css';
const DisclaimerSection = () => {
  return (
    <div className="disclaimer-text">
      <span className="red-disclaimer-text">Disclaimer:</span> DGV neither charges any fee, nor requires any money
      deposits from job seekers at any stage of recruitment nor collect any fees from educational institutions for
      participation in a recruitment event. DGV has a robust recruitment process where the employment criterion is based
      purely on merit. Any person dealing with unauthorized parties for seeking job opportunities with DGV in lieu of
      money is doing so at his/her own risk. DGV will not have any obligation to honour terms of any fake offer letter
      so issued or provide employment to anyone who has been issued a fraudulent offer letter. Further, DGV is not
      responsible for any losses (monetary or otherwise) including but not limited to loss of data that are incurred as
      a consequence. In case you come across any person or organization demanding money in lieu of an offer letter for a
      job opportunity in DGV, you are encouraged to bring such matters to our attention by reporting it to
      <span className="blue-disclaimer-text"> hr@dgv.in</span>
    </div>
  );
};

export default DisclaimerSection;
