import React, { useState } from 'react';
import AttachmentIcon from '../../../assets/images/Careers/attachmentIcon.svg';
import './FormFileInput.css';
export default function FormFileInput({ handleFileChange, name }) {
  const [fileData, setFileData] = useState();

  const setData = (param) => {
    setFileData(param['0'].name);
  };
  return (
    <div className="_form_input_row">
      <div className="_custom_file">
        <div className="attach-icon">
          <img src={AttachmentIcon} alt="AttachmentIcon"></img>
        </div>
        <input
          type="file"
          name={name}
          onChange={(e) => {
            setData(e.target.files);
            const files = Array.from(e.target.files);
            handleFileChange(files[0]);
          }}
        />
        <span className="resume-text"> Attach your Resume </span> <span className="resume-file">{fileData}</span>
      </div>
    </div>
  );
}
